html.theme-applied #running-ponies {
    padding:24px 24px 16px;
    margin:0;
    background:#ffffff50;
}
html.theme-applied #running-ponies > * {
    display:block;
}
@media (max-width:1199px) { html.theme-applied #running-ponies > *:nth-child(n+20) { display:none; } }
@media (max-width:1144px) { html.theme-applied #running-ponies > *:nth-child(n+19) { display:none; } }
@media (max-width:1089px) { html.theme-applied #running-ponies > *:nth-child(n+18) { display:none; } }
@media (max-width:1034px) { html.theme-applied #running-ponies > *:nth-child(n+17) { display:none; } }
@media (max-width:979px) { html.theme-applied #running-ponies > *:nth-child(n+16) { display:none; } }
@media (max-width:927px) { html.theme-applied #running-ponies > *:nth-child(n+15) { display:none; } }
@media (max-width:875px) { html.theme-applied #running-ponies > *:nth-child(n+14) { display:none; } }
@media (max-width:823px) { html.theme-applied #running-ponies > *:nth-child(n+13) { display:none; } }
@media (max-width:771px) { html.theme-applied #running-ponies > *:nth-child(n+12) { display:none; } }
@media (max-width:719px) { html.theme-applied #running-ponies > *:nth-child(n+11) { display:none; } }
@media (max-width:681px) { html.theme-applied #running-ponies > *:nth-child(n+10) { display:none; } }
@media (max-width:643px) { html.theme-applied #running-ponies > *:nth-child(n+9) { display:none; } }
@media (max-width:605px) { html.theme-applied #running-ponies > *:nth-child(n+8) { display:none; } }
@media (max-width:567px) { html.theme-applied #running-ponies > *:nth-child(n+7) { display:none; }
    html.theme-applied #running-ponies { padding:16px 16px 16px; }
}
@media (max-width:517px) { html.theme-applied #running-ponies > *:nth-child(n+6) { display:none; } }
@media (max-width:467px) { html.theme-applied #running-ponies > *:nth-child(n+5) { display:none; } }

@keyframes animatedBackground {
    from { transform:translateX(var(--x-start)); }
    to { transform:translateX(0); }
}
html.theme-applied #header {
    --x-start:-1920px; position:relative;
    padding-bottom:8px;
}
html.theme-applied #header > * { position:relative; }
html.theme-applied #header::before {
    content:''; position:absolute; top:0; bottom:0; width:3840px;
    z-index:0; transform:translateX(var(--x-start));
    background:#fff repeat-x var(--x-start) bottom;
    animation:animatedBackground 40s linear infinite;
    padding-bottom:8px;
}
html.theme-applied #header::after {
    content:''; position:absolute; bottom:0; height:40px; width:3840px;
    transform:translateX(var(--x-start));
    background:repeat-x var(--x-start) bottom;
    animation:animatedBackground 40s linear infinite;
}
html.theme-applied.no-animations #header::before,
html.theme-applied.no-animations #header::after {
    width:auto; right:0; left:0;
    background-position-x:center;
    animation:none;
}

html.theme-applied #wrapper { background:none; padding-top:16px; }
html.theme-applied #sidebar>*:first-child { margin-top:0; }
@media (min-width:568px) and (max-width:979px) {
    html.theme-applied body.widemode #dropdown-user { padding-bottom:127px; }
    html.theme-applied body.widemode #header-menu .auth { padding-bottom:124px; }
    html.theme-applied body.widemode #dropdown-user::after, html.theme-applied body.widemode #header-menu .auth::after {
        content:''; position:absolute; right:0; bottom:0; left:0; height:124px; background:#fff;
    }
    html.theme-applied body.widemode #sidebar>*:first-child {
        margin-top:-92px;
    }
}


html.theme-applied.theme--winter #header::before {
    background-image:url(../images/themes/winter_header_bg.svg);
}
html.theme-applied.theme--winter #header::after {
    background-image:url(../images/themes/winter_header_fg.svg);
}
html.theme-applied.theme--winter #header-main {
    background:#1d2a4fdd;
}
html.theme-applied.theme--winter #header-menu {
    background:#e8ebfcdd;
}
html.theme-applied.theme--winter #running-ponies {
    background:none;
}
@media (min-width:568px) and (max-width:1199px) {
    html.theme-applied.theme--winter #header-main {
        background:linear-gradient(#1d2a4fff calc(100% - 80px),#1d2a4fdd calc(100% - 80px),#1d2a4fdd);
    }
}


html.theme-applied.theme--hearths-warming-eve #header::before {
    background-image:url(../images/themes/hearths-warming-eve_header_bg.svg);
}
html.theme-applied.theme--hearths-warming-eve #header::after {
    background-image:url(../images/themes/hearths-warming-eve_header_fg.svg);
}
html.theme-applied.theme--hearths-warming-eve #header-main {
    background:#1d2a4fcc;
}
html.theme-applied.theme--hearths-warming-eve #header-menu {
    background:#e8ebfccc; text-shadow:#fffa 0 0 2px;
}
html.theme-applied.theme--hearths-warming-eve #running-ponies {
    background:none;
}
@media (min-width:568px) and (max-width:1199px) {
    html.theme-applied.theme--hearths-warming-eve #header-main {
        background:linear-gradient(#1d2a4fff calc(100% - 80px),#1d2a4fcc calc(100% - 80px),#1d2a4fcc);
    }
}
