button,
input,
select,
textarea
  font-family Verdana, sans-serif

.input-text
  width 150px
  padding 5px
  border 1px solid #c9c9c9
  -webkit-box-shadow 0 2px 4px rgba(0,0,0,.07) inset
  box-shadow 0 2px 4px rgba(0,0,0,.07) inset
  border-radius 3px
  -moz-box-sizing border-box
  -webkit-box-sizing border-box
  box-sizing border-box
  &:focus
    border-color #57BCEB
    -webkit-box-shadow 0 2px 4px rgba(0,0,0,.07) inset, 0 0 3px #00A8FF
    box-shadow 0 2px 4px rgba(0,0,0,.07) inset, 0 0 3px #00A8FF
    outline none

.input-checkbox
  margin 0 2px 0 1px

div.input-checkbox {
  margin:0 0 12px 0;
  text-indent:-22px/*-24px*/;
  padding:0 0 0 22px/*24px*/;
  &>*{ text-indent:initial; }
  &>label {
    display:inline-block;
    margin:0 0 4px 0;
    &>input[type="checkbox"] {
      /*width:16px; height:16px;*/ margin-right:8px;
    }
  }
  &>p,&>div { margin:0 0 8px 0; color:#868e96 /* gray 6 */ }
}

.input-width-full
  width 100%

.input-width-50
  width 50px

.input-width-100
  width 100px

.input-width-150
  width 150px

.input-width-200
  width 200px

.input-width-250
  width 250px

.input-width-300
  width 300px

.input-width-400
  width 400px

.input-width-500
  width 500px

textarea
  -moz-box-sizing border-box
  -webkit-box-sizing border-box
  box-sizing border-box

select
  padding 4px
  border 1px solid #ddd
  border-radius 3px

fieldset
  margin-bottom 30px
  padding-top 20px
  border-top 1px solid #eaeaea
  legend
    color #000
    font-size 18px
    padding-right 10px

form
  p
    margin-bottom 20px
  label
    display block
    margin-bottom 3px
  .icon-question-sign
    cursor help

.note
  display block
  margin-top 3px
  font-size 11px
  line-height 16px
  color #aaa
  &.note-header
    margin-bottom 20px

.captcha-image
  vertical-align top

.counter-wrapper
  margin-bottom 15px
  position relative
  .counter
    color #777
    position absolute
    top 0
    right 0
  label
    margin-right 50px

.poll-create
  padding 15px 20px
  margin-bottom 20px
  background #F1F7FD

.question-list
  margin-bottom 15px
  li
    margin-bottom 5px
    .input-text
      margin-right 5px

dl
  &.form-item
    overflow hidden
    margin-bottom 20px
    dt
      float left
      width 125px
      padding-top 4px
      padding-right 15px
      overflow hidden
    dd
      float left
      width 250px

:-moz-placeholder
  color #aaa

::-webkit-input-placeholder
  color #aaa

.placeholder
  color #aaa

.mceEditor
  display block
  margin-bottom 20px

.registration-form
  .form-item
    &:last-child
      margin-bottom 0
  dl
    &.form-item
      dd
        position relative
        width 355px
        .form-item-help
          width 100px
          height 20px
          position absolute
          top 1px
          right 0
          &.active
            background url(images/dots.png) 0 12px repeat-x
          i
            background-color #f8fbfe
            &.icon-ok-green
              position absolute
              top 3px
              right 0

.validate-error-hide
  display none

.validate-error-show
  display block
  line-height 16px
  position relative
  padding 10px 15px 12px 38px
  background #fefef1 url(images/error.png) 15px 12px no-repeat
  color #363636
  margin-top 13px
  font-size 12px
  border-radius 3px
  -webkit-box-shadow 0 1px 5px #e8e8c0 inset
  box-shadow 0 1px 5px #e8e8c0 inset
  &:before
    display block
    content ""
    position absolute
    top -12px
    left 15px
    width 0
    height 0
    border-width 6px
    border-color transparent transparent #F2F2DD transparent
    border-style solid
