.modal
  display none
  position fixed
  left 50%
  margin-left -150px
  color #333
  background-color #fbfcfc
  border-radius 10px
  -webkit-box-shadow 0 0 15px rgba(0,0,0,.5)
  box-shadow 0 0 15px rgba(0,0,0,.5)
  overflow hidden
  @media screen and (min-height: 500px)
    top: 25%
  @media screen and (max-height: 500px)
    top: 0
  padding 20px
  .close
    position absolute
    top 7px
    right 8px
    width 16px
    height 16px
    background url(images/icons-synio.png) no-repeat -131px -39px
    &:hover
      background-position -131px -55px
  .modal-header
    padding 15px 30px 0
    h3
      font-size 20px
      font-weight bold
      margin-right 20px
  .modal-content
    padding 30px 30px
  &.modal-login
    width 450px
    margin-left -225px
    top 50px
    .nav
      margin-bottom 30px
    .modal-header
      margin-bottom 0
    .modal-content
      padding-top 15px
    .remember-label
      font-size 11px
      color #6c7272
      input
        top 2px
    .icon-question-sign
      position relative
      top -3px
    .icon-ok-green
      position relative
      top -1px
    .js-block-popup-login-content
      .button
        position relative
        top -1px
        margin-left 1px
  &.modal-write
    width 620px
    margin-left -310px
    top 70px
    background #e0eff9
    .modal-content
      padding 20px 10px 20px
    .write-list
      overflow hidden
      li
        width 125px
        margin 0 10px 20px
        font-size 19px
        text-align center
        overflow hidden
        display inline-table
        *float left
        a
          color #727a90
        &:hover
          a
            color #275ec2
        .write-item-image
          display block
          margin 0 auto
          width 115px
          height 115px
          border-radius 3px
          text-align center
          background url(images/write.png) no-repeat
        &.write-item-type-draft
          padding-right 20px
          margin-right 0
          border-right 1px dotted #aac6d9
          .write-item-image
            background-position -578px 0
          &:hover
            .write-item-image
              background-position -578px -115px
        &.write-item-type-topic
          .write-item-image
            background-position 0 0
          &:hover
            .write-item-image
              background-position 0 -115px
        &.write-item-type-poll
          .write-item-image
            background-position -115px 0
          &:hover
            .write-item-image
              background-position -115px -115px
        &.write-item-type-link
          .write-item-image
            background-position -230px 0
          &:hover
            .write-item-image
              background-position -230px -115px
        &.write-item-type-message
          .write-item-image
            background-position -345px 0
          &:hover
            .write-item-image
              background-position -345px -115px
        &.write-item-type-blog
          .write-item-image
            background-position -115px 0
          &:hover
            .write-item-image
              background-position -115px -115px
  &.modal-image-upload
    width 500px
    margin-left -250px

.react-modal
  margin-left 0
  transform translateX(-50%)

  .modal-content
    position relative
    padding 0
    margin 0 20px 20px

  .modal-header
    text-align center
    padding 5px 5px 0 5px

    h3
      margin 0 20px 10px

  .modal-actions
    display flex
    justify-content flex-end

.jqmOverlay
  background-color #000

*
  html
    .modal
      position absolute

#window_login_form
  position absolute
  z-index 2999

// Only for Modal component
.overlay
  top 0
  left 0
  height 100%
  width 200%
  position fixed
  z-index 2999
  opacity 0.5
  background-color: #000

.overlay-cover
  z-index 3000

.react-modal
  &.opened
    display block
