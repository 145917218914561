.ta-c
  text-align center !important

.ta-r
  text-align right !important

.h-float-right
  float right

.h-hidden
  display none

.fl-l
  float left !important


.clearfix:before,
.clearfix:after
  content ""
  display table

.clearfix
  &:after
    clear both

.word-wrap
  white-space normal
  word-wrap break-word

.input-width-full, .width-full
  width 100%

.input-width-50 ,  .width-50
  width 50px

.input-width-100,  .width-100
  width 100px

.input-width-150,  .width-150
  width 150px

.input-width-200,  .width-200
  width 200px

.input-width-250,  .width-250
  width 250px

.input-width-300,  .width-300
  width 300px

.input-width-400,  .width-400
  width 400px

.input-width-500,  .width-500
  width 500px

.link-dashed
  text-decoration none
  border-bottom 1px dashed
  cursor pointer

/*.link-dotted
  text-decoration none
  border-bottom 1px dotted
  cursor pointer*/

.highlight {
  background: yellow;
}
