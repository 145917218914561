#container
  position relative
  overflow hidden
  background:#fff;
  margin 0 auto
  max-width 1600px
  &.toolbar-margin
    padding 0

#header-menu
  background url(images/menu-bg.jpg) no-repeat center top #E8EBFC
  min-height 70px
  padding 0 24px
  position relative
  ul
    display flex
    flex-flow row
    flex-wrap wrap
    justify-content left

#nav
  min-height 26px
  padding 0 0 4px
  position relative

#wrapper
  display flex
  flex-flow row-reverse
  margin-bottom 48px
  background:#fff url(images/content-bg.jpg) no-repeat center -77px;
  &.no-sidebar
    background-image none
    margin-bottom 0
    #content
      width 100%
      margin-right 0
    #content-wrapper
      margin-right 0
      width 100%

#content
  position relative

#footer
  color #eee
  text-align center
  font-size 16px
  padding 12px 0 8px 0
  background #1d2a4f url(images/header-bg.jpg) no-repeat center center
  a
    color #eee
  .version
    margin-top 8px

#header-main
  display:flex;
  align-items:center;
  min-height:80px
  padding:0 24px;
  font-family Georgia, Times, serif
  background #1d2a4f url(images/header-bg.jpg) no-repeat center bottom
  .main-menu
    color #cdcae9
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    margin-right:62px;
    a
      font-size 20px
      line-height 1.2em
      margin-right 20px
      color #fafae8
      &:hover
        color #fff
      &:last-of-type
        margin-right:0;
    a#logolink
      font-size 36px
      padding 0 42px 0 0
      white-space nowrap
      color #cdcae9
      text-decoration none
  .menu-social
    margin-left:auto;
    display:flex;
    a
      flex:0 0 32px;
      width:32px;
      height:32px;
      background:url(images/header-social.svg) no-repeat 0 0;
      margin-right:8px;
      &:hover
        background-position-x:right;
      &:last-of-type
        margin-right:0;
    a.rss
      background-position-y:0;
    a.tg
      background-position-y:-32px;
