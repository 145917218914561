.topic
  margin-bottom 48px
  &:last-child
    margin-bottom 0
  &.link-preview-box
    border-radius:8px;
    box-shadow:0 0 2px 2px #dddddd80;
    padding:24px;
    background-color:#ffffff80;
    margin-bottom:32px;

.topic-info
  .avatar
    width 20px
    height 20px
    margin-bottom -5px

.private-blog:after
  background transparent url(images/lock.png) no-repeat 100% 0
  margin-left 4px
  content ""
  display inline-block
  vertical-align middle
  width 16px
  height 16px

.topic-header
  position relative
  margin 0 0 8px 56px
  min-height 48px
  .topic-title
    font-size 23px
    line-height 1.1em
    font-weight normal
    i
      position relative
      top 9px
      cursor help
      margin-right 5px
    .topic-info-vote
      i
        margin-right 0
        top 0
  .topic-info
    padding-top 4px
    color #777
    display:flex;
    align-items:center;
    gap:4px;
    flex-wrap:wrap;
    .topic-blog
      color #999
      &:hover
        color #666
    .blog-list-info
      position relative
      top 5px
    .avatar
      position absolute
      left -56px
      /*top 50%
      margin-top -24px*/
      top:0;
      margin-top:2px;
      width 48px
      height 48px
  .topic-actions
    display:flex;
    align-items:center;
    gap:16px;
    margin-left:auto;
    font-size 13px
    overflow hidden
    span
      i
        margin-right 3px

.topic-content
  .topic-header
    display none
  &.text
    color #333

.topic-tags, .topic-tags a
  margin 0
  color #999

.topic-tags
  a
    text-decoration underline
    &:hover
      text-decoration none
    &.link-dotted
      text-decoration none
      &:hover
        color #333
        border-color #333
  .topic-tags-edit
    margin-left 20px
    a
      text-decoration-style dotted
  .topic-tags-user
    a
      color #139643

.topic>a.topic-cut-link {
  margin-top:16px;
  background-color:#f1f3f5; // gray 1
  border:1px dashed #868e96; // gray 6
  display:inline-block;
  padding:8px 28px 8px 12px;
  font-size:13px;
  line-height:1.6em;
  &::after {
    content:'→';
    position:absolute;
    padding-left:4px;
  }
}
.topic-preview {
  .topic-preview-header {
    display:flex;
    align-items:center;
    border-top:1px solid #ddd;
    padding:8px 0 16px 0;
    h3 {
      font-weight:bold;
    }
    label {
      margin-left:auto;
      input { margin-right:4px; }
    }
  }
  &.show-helpers .text {
    a#cut {
      background-color:#f1f3f5; // gray 1
      border:2px dashed #868e96; // gray 6
      padding:8px 12px;
      display:block;
      text-align:center;
      color:#495057; // gray 7
      text-decoration:none;
      font-style:italic;
      &::before {
        content:attr(data-cut-title);
      }
      &:hover {
        color:#495057; // gray 7
      }
    }
    .spoiler>.spoiler-body {
      border:2px dashed #868e96; // gray 6
      padding:5px;
    }
  }
}
.topic-footer
  margin-top:16px;
  .topic>a.topic-cut-link+&
    margin-top:8px;
  .topic-info
    overflow hidden
    display flex
    flex-flow row
    align-items center
    gap 16px
    min-height:24px;
    .topic-info-author
      padding-left 24px


.topic-info-vote
  position:relative;
  .vote-count
    min-width 22px
    text-align center

.topic-info
  .topic-info-date
    color #7b848d
  .topic-info-favourite
    white-space nowrap
  .topic-info-comments
    a
      color #727fa0
      display:flex;
      gap:4px;
      align-items:center;
      position:relative;
      &:hover
        color #f00
      &.new
        color #000
    span
      text-decoration underline
      font-weight bold
      &.count
        color #27a736
        text-decoration none
        position:absolute;
        left:100%;
        padding-left:4px;
    i
      display:inline-block;
      vertical-align:middle;

.poll
  margin-bottom 16px
  .poll-vote
    margin-bottom 12px
    margin-left 0
    list-style-type none
    li
      margin-bottom 8px
      &:last-child
        margin-bottom 0
      label
        display inline
      input
        position relative
        top 1px
        margin-right 4px
  .poll-submit
    display:flex;
    gap:8px;
    flex-wrap:wrap;
    .poll-submit-vote
      display:flex; gap:8px;
    .poll-submit-close
      margin-left:auto;
  .poll-result
    list-style-type none
    overflow hidden
    li
      margin-bottom 15px
      dl
        overflow hidden
        dt
          float left
          width 48px
          span
            font-size 11px
            color #818189
        dd
          padding-left 48px
          div
            height 21px
            overflow hidden
            background #ccc url(images/poll.jpg) no-repeat 0 0
            background-size auto 21px
  .poll-total
    position relative
    padding-left 48px
    margin-bottom 20px
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    gap:8px 16px;
    .poll-total-counts
      color #818189
      font-size 11px
      line-height 16px
    .poll-total-isclosed
      font-weight:bold;
    .poll-sort
      position absolute
      top 2px
      left 0
      width 23px
      height 23px
      background url(images/icons-synio.png) -161px 0 no-repeat
      cursor pointer
      &.active
        background-position -184px 0
    .poll-submit-reopen
      margin-left:auto;

.topic-preview
  margin-top 30px
  .profile-page-header
    border-top 1px solid #ddd
  .topic
    margin-bottom 20px

.vote-topic-info
  li
    margin-bottom 3px

.topic.topic-type-file .topic-file-file {
  margin-top:32px;
  & > a {
    display:inline-block; position:relative;
    padding:8px 64px 8px 64px;
    border-radius:8px;
    background-color:#edf2ff; // indigo 0
    color:#495057; // gray 7
    text-decoration:none;
    &:hover {
      background-color:#dbe4ff; // indigo 1
    }
    &::before, &::after {
      content:'';
      position:absolute;
      top:0; bottom:0; margin:auto 0;
      width:32px; height:32px;
    }
    &::before {
      width:48px; height:48px;
      left:8px; background:url(images/scroll.svg) no-repeat center center / contain;
    }
    &::after {
      width:24px; height:24px;
      right:8px; background:url(images/download.svg) no-repeat center center / contain;
    }
    &>* {
      display:block;
      &.topic-file-title {
        font-weight:bold;
        color:#343a40; // gray 8
      }
    }
  }
}

.topic.topic-type-link .topic-link-link {
  font-size:13px;
  line-height:1.6em;
  margin-top:8px;
}
.topic.topic-type-photoset .topic-photoset-photoset {
  margin-top:24px;
}
