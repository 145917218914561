.table
  width 100%
  margin-bottom 15px
  border-collapse separate
  th
    text-align left
    font-weight normal
    border-bottom 1px solid #c3d4dc
  td
    padding 7px 10px
  tr
    &:hover
      td
        background #f5fdff
    &.active
      td
        background #CBF4BC
  tbody
    td
      border-top 1px solid #f1f3f5
    tr
      &:first-child
        td
          border-top none
  thead
    a
      color #727a90
      text-decoration none
      span
        border-bottom 1px dotted #727a90
  a
    &.asc
      &:after
        content " ↑"
    &.desc
      &:after
        content " ↓"
  .cell-rating
    &.negative
      color #f00 !important
  .cell-tab
    padding 0
    .cell-tab-inner
      position relative
      top 1px
      display inline-block
      border 1px solid transparent
      border-radius 3px 3px 0 0
      border-bottom none
      width 100%
      box-sizing border-box
      &.active
        background #fff
        border-color #c3d4dc
  &.table-blogs
    .cell-info
      width 19px
      padding-right 0
      padding-left 0
    tbody
      .cell-info
        vertical-align top
        .blog-list-info
          display block
      .cell-rating
        color #5fa459
        font-weight bold
    .cell-name
      padding-left 3px
      .avatar
        float left
        margin-right 10px
        clear right
      p
        overflow hidden
        .blog-name
          font-size 18px
          color #275ec2
        i
          vertical-align middle
          position relative
          top -1px
    .cell-rating,.cell-readers,.cell-join
      width 90px
      text-align center
      font-size 11px
      vertical-align middle
    .cell-join
      width 40px
  &.table-plugins
    .cell-checkbox
      width 20px
      padding 12px 0 10px 10px
    h3
      font-weight bold
      font-size 17px
      line-height 20px
      margin-bottom 5px
  &.table-profile-notes
    border-top 1px solid #eee
    td
      vertical-align top
    .cell-username
      width 20%
      font-weight bold
    .cell-note
      width 50%
    .cell-date
      text-align right
  &.table-profile-info
    margin-bottom 40px
    td
      vertical-align top
    .cell-label
      width 30%
      color #777
  &.table-users
    th
    .cell-follow
      width 17px
      text-align center
    thead
      .cell-follow
        i
          position relative
          top 3px
    .cell-name
      padding-right 0
      padding-left 0
      img
        float left
        margin-right 14px
      .name
        margin-top 2px
        &.no-realname
          margin-top 11px
      .username
        margin-bottom 3px
      .realname
        font-size 11px
    tbody
      .cell-name
        div
          float left
      .cell-date
        font-size 11px
      .cell-skill
        color #628fa5
        font-size 11px
        font-weight bold
      .cell-rating
        color #5fa459
        font-size 11px
    .cell-name+th,.cell-name+td,.cell-name+td+td
      width 40px
      text-align center
    .cell-rating,.cell-skill
      text-align center
      width 70px
    button
      float left
      margin-right 5px
  &.table-talk
    .cell-checkbox
      width 20px
      padding 9px 0 10px 10px
    thead
      .cell-checkbox
        padding 10px 0 10px 10px
    .cell-favourite
      text-align center
    tbody
      .cell-favourite
        padding 17px 0
      .cell-recipients
        font-size 11px
        a
          color #000
      .cell-date
        font-size 11px
        width 115px
        line-height 14px
      .cell-title
        a
          color #275ec2
          font-size 15px
          strong
            color #DA5252
          &:hover
            color #f00
        span
          color #628fa5
          font-weight bold
          margin-left 4px
        .new
          color #32b319
        i
          position relative
          left -3px
      td
        vertical-align top
    .cell-recipients
      width 130px

.table .cell-tab .cell-tab-inner
  padding 6px 10px 8px

.table a.asc, .table a.desc
  color #000
  border-color #000
