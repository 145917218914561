.button
  display inline-block
  padding 3px 10px
  margin 0
  text-align center
  vertical-align middle
  text-decoration none
  line-height 20px
  text-shadow 0 1px 1px rgba(255, 255, 255, 0.75)
  border 1px solid #ddd
  border-radius 3px
  color #333
  background #fafafa
  //background linear-gradient(#fff 0%,#e6e6e6 100%)
  box-shadow 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05)
  cursor pointer
  &:hover
    text-decoration none
    background #eee
  &.loading
    position relative
  &.loading::before
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    border-radius 3px
    background #fafafa
  &.loading::after
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    margin auto
    width 8px
    height 8px
    border-radius 50%
    box-shadow -12px 0 0 0 #334470, inset 0 0 0 8px #33447088, 12px 0 0 0 #33447000
    animation loading-light 1.5s infinite linear
  &.button-primary
    color #fff
    text-shadow 0 -1px 0 rgba(0, 0, 0, 0.25)
    border-color #213162
    background #334470 none repeat scroll 0 0
    &:hover
      background #4B64A4 none repeat scroll 0 0
    &.loading::before
      background #334470
    &.loading::after
      box-shadow -12px 0 0 0 #fafafa, inset 0 0 0 8px #fafafa88, 12px 0 0 0 #fafafa00
      animation loading-dark 1.5s infinite linear
  &.button-small
    font-size 11px
    padding 1px 7px 3px
  &.button-action
    line-height 30px
    border 1px solid #aaa
    padding 0 4px 0
    background #e8ebfc
    span
      display none
    i
      vertical-align middle
    &:hover
      span
        display inline
        padding 0 5px 0 5px
        position relative
        top -1px
        color #fff
    &.button-action-add-friend
      &:hover
        i
          background-position -147px -39px
    &.button-action-join
      padding 0 7px
    &.button-action-send-message
      padding 0 7px
      &:hover
        i
          background-position -163px -38px
    &.button-action-note
      background #ccf4d0
      padding 0 9px 0 10px
      cursor help
      &:hover
        background #4ace56
        i
          top 0
          background-position -46px -65px

#form_comment
  .button
    margin-top 2px

.button.button-action.active,
.button.button-action:hover
  color #fff
  background #c8f5ff

/*.button.button-action.button-action-join.active i,
.button.button-action.button-action-join:hover i
  background-position -179px -39px*/

.button[disabled], .button[disabled]:hover
  cursor default
  text-shadow none
  background-color #f5f5f5
  color #ccc
  border-color #eee

.button:active,
.button.active
  background #eaeaea
  border-color #ccc
  -webkit-box-shadow 0 0 5px rgba(0, 0, 0, .2) inset
  -moz-box-shadow 0 0 5px rgba(0, 0, 0, .2) inset
  box-shadow 0 0 5px rgba(0, 0, 0, .2) inset
