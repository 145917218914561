.profile {
    display:flex;
    align-items:center;
    gap:32px;
    min-height:48px;
    margin-bottom:30px;
}
.profile .avatar {
    background:#fff;
    padding:5px;
    /*border:1px solid #aaa;*/
    box-shadow:0 0 5px rgba(0,0,0,.2);  flex:0 0 100px;/*80px*/
    border-radius:5px;
    height:100px;/*80px*/
}
.profile .profile-name {
    flex:1 1 100%;
}
.profile .user-login {
    width:100%;
    font-size:28px;
    line-height:36px;
    font-weight:normal;
    margin-bottom:5px;
    overflow-wrap:anywhere;
}
.profile .user-name {
    color:#80838c;
    font-size:18px;
}
.profile .vote-label {
    /*text-transform:lowercase;
    text-align:center;
    font-size:12px;
    color:#80838c;*/
    font-size:13px; line-height:16px;
    text-align:center; color:#80838c;
}
/*.profile .strength .count {
  font-size:18px;
  line-height:1em;
  text-align:center;
  padding:0 10px;
  color:#628fa5;
}*/
.profile .vote-profile {
    position:relative;
}
.profile .profile-stats {
    display:flex; align-items:center; gap:32px;
}
.profile .profile-stats .profile-stats-counts {
    font-size:14px; line-height:18px;
    text-align:center; margin:0;
}
.profile .profile-stats a.profile-stats-counts { color:#275ec2; /*text-decoration:none;*/ }
.profile .profile-stats .profile-stats-counts > span {
    display:block; font-size:18px; line-height:22px;
}
.profile .profile-stats a.profile-stats-counts:hover { color:#f00; }
.profile .profile-stats .profile-stats-counts.active {
    color:#333; font-weight:bold;
}

/*.profile-page-header {
  font-size:12px;
  font-weight:bold;
  margin-bottom:20px;
  background:#f5f5f5;
  border-top:1px solid #eee;
  color:#555;
  padding:5px 10px 6px;
}*/
.profile-info-about {
    margin-bottom:10px;
    color:#111;
    position:relative;
}
.profile-info-about h3 {
    font-size:19px;
    margin-bottom:5px;
}

.profile .profile-status-wrapper { margin-top:5px; }
.profile .profile-status-wrapper:empty { margin-top:0; }
.profile .profile-status {
    display:inline-block; line-height:normal;
    padding:5px; border-radius:5px; background:#eee;
    cursor:default;
}
.profile .profile-status.status-admin {
    background:#ffa94d;
}
.profile .profile-status.status-blocked, .profile .profile-status.status-blocked-rating {
    background:#ff8787; color:#fff;
}

.profile-403 {
    text-align:center;
    background:url(../images/403-private-profile.svg) no-repeat center top / 256px;
    padding-top:256px;
}
.profile-403>h2 { color:#495057 /* gray 7 */; font-weight:700; }
.profile-403>h3 { color:#495057 /* gray 7 */; }

.profile-dotted-list {
    margin-bottom:20px;
}
.profile-dotted-list li {
    overflow:hidden;
    margin-bottom:5px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAMAAADD/I+4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYwRjExQzlEOEZDMjExRTE4NEMyOUE0MTdGOTExNzZBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYwRjExQzlFOEZDMjExRTE4NEMyOUE0MTdGOTExNzZBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RjBGMTFDOUI4RkMyMTFFMTg0QzI5QTQxN0Y5MTE3NkEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RjBGMTFDOUM4RkMyMTFFMTg0QzI5QTQxN0Y5MTE3NkEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4n2eZyAAAABlBMVEX////R2NwfDV2RAAAADUlEQVR42mJgZAAIMAAABQACEwstsgAAAABJRU5ErkJggg==') 0 14px repeat-x;
}
.profile-dotted-list li span {
    background:#fff;
}
.profile-dotted-list li strong {
    float:right;
    width:80%;
    font-weight:normal;
    background:#fff;
}

.profile-contact-list {
    margin-bottom:50px;
}
.profile-contact-list li {
    margin-bottom:5px;
    padding-left:21px;
    position:relative;
}
.profile-contact-list li i {
    position:absolute;
    top:2px;
    left:0;
}


/* Profile settings */

.profile-about-wrapper { margin-bottom:20px; }
#profile_about { resize:vertical; }

.avatar-change {
    display:flex;
    flex-direction:column;
    float:right;
}
.avatar-change .avatar-image-wrapper {
    width:100px;
    margin:0 auto;
}
.avatar-change-input,.profile-photo-change-input {
    margin-top:5px;
    text-align:center;
    color:#aaa;
}
.avatar-change-input label,.profile-photo-change-input label {
    color:#727a90;
    border-color:#727a90;
}
.avatar-change a.remove-image,.profile-photo-change a.remove-image {
    color:#d20000;
    border-color:#d20000;
}
.avatar-image-wrapper,.profile-photo-image-wrapper {
    border-radius:5px;
    background:#fff;
    padding:5px;
    box-shadow:0 0 5px rgba(0,0,0,.2);
    text-align:center;
}
.avatar-image-wrapper img, .profile-photo-image-wrapper img,
img.avatar-image-wrapper, img.profile-photo-image-wrapper {
    display:block; margin:0 auto;
}
.avatar-image-wrapper .remove-image,.profile-photo-image-wrapper .remove-image {
    margin-top:5px;
}
.avatar-dragndrop-wrapper,.profile-photo-dragndrop-wrapper {
    position:relative;
    display:inline-block;
    margin-bottom:10px;
    padding:10px;
    border:2px dashed #ccc;
}
.avatar-dragndrop-wrapper.dragging,.profile-photo-dragndrop-wrapper.dragging {
    border-color:#888;
}
.avatar-dragndrop-wrapper.dragging::before,.profile-photo-dragndrop-wrapper.dragging::before {
    content:''; position:absolute;
    top:0; right:0; bottom:0; left:0;
    background:rgba(0,0,0,0.4);
}
.avatar-dragndrop-wrapper.dragging::after,.profile-photo-dragndrop-wrapper.dragging::after {
    content:''; position:absolute;
    top:0; right:0; bottom:0; left:0;
    width:80%; height:80%;
    max-width:128px; max-height:128px;
    background:url(../images/dragndrop.svg) no-repeat center center / contain;
    margin:auto;
}

.settings-demo-comment-goto-icon, .settings-demo-comment-goto-revert-icon {
    display:inline-block;
    width:14px;
    height:14px;
    background:transparent url(../images/icons-comment.png) no-repeat 0 0;
}
.settings-demo-comment-goto-revert-icon { transform:rotate(180deg); }
