.block
  background-color #f4f4f6
  border-bottom 1px dotted #DCE1E6
  border-radius 5px
  margin-bottom 25px
  padding 15px
  &:last-child
    margin-bottom 0
    border none
  &.block-type-stream
    background #f4f4f6 url(images/trixie.png) no-repeat 100% 100%
  &.block-type-blogs
    background #f4f4f6 url(images/twi.png) no-repeat 100% 100%
    .block-blog-list
      li
        margin-bottom 10px
        background url(images/dots.png)  0 14px repeat-x
        position relative
        padding-right 50px
        a
          color:#868e96; // gray 6
          padding-right 5px
          &:hover
            color:#2c82fc;
        i
          position relative
          top -2px
        strong
          position absolute
          top 0
          right 0
          padding-left 2px
          font-weight normal
          font-size 11px
  .block-header
    position relative
    padding-bottom 16px
    h3
      font-size 22px
      line-height 1em
      font-weight normal
      a
        text-decoration none
        color #333
        &.link-dotted
          color #5055B2
  footer
    margin-top 8px
    color:#868e96; // gray 6
    a
      color:#495057; // gray 7
      &:hover
        color:#2c82fc;
  .max-height-200
    overflow auto
    max-height 200px
    position relative
  .nav
    &.nav-pills
      margin-bottom 0
      padding-top 15px
      a
        text-decoration none
        border-bottom 1px dotted #727a90
      li
        &.active
          a
            border none
  .block-update
    display inline-block
    width 20px
    height 20px
    background url(images/icons-synio.png) 0 -200px no-repeat
    cursor pointer
    &:hover
      background-position -20px -200px
    &.active
      background-position -40px -200px
  &.block-type-activity
    p
      margin-bottom 15px
    ul
      li
      &.stream-settings-blogs
        li
          margin-bottom 3px
    .note
      display block
      margin-bottom 15px
      color #aaa
  &.block-type-profile
    background none
    border none
    padding 0
    .profile-photo-wrapper
      position relative
      min-height 50px
      text-align center
    .profile-photo
      vertical-align top
      max-width 100%
    .status
      position absolute
      top 10px
      left 0
      padding 0 10px 0 10px
      font-size 11px
      line-height 18px
      height 20px
      &:before
        position absolute
        top 0
        right -20px
        width 0
        height 0
        border-width 10px
        border-style solid
        border-color #333 transparent #333 #333
      &.status-online
        background #8961e4
        color #fff
        &:before
          border-color #8961e4 transparent #8961e4 #8961e4
      &.status-offline
        background #fff
        color #333
        opacity .5
        &:before
          border-color #fff transparent #fff #fff
  &.block-type-profile-note
    border 2px solid #ffe25d
    padding 15px
    border-radius 5px
    p
      margin-bottom 10px
    .actions
      margin-bottom 0
      font-size 11px
      a
        color #275ec2
        border-color #275ec2
        &:hover
          color #f00
          border-color #f00
  &.block-type-profile-nav
    margin-top:15px;
    padding 0
    background none
    border none
  &.block-type-profile-actions
    border none
    .block-content
      padding 0 0 0 30px
    ul
      li
        margin-bottom 5px
        font-size 11px
        &:last-child
          margin-bottom 0
        a
          color #727a90
          &:hover
            color #f00
          &.followed
            color #f00
  &.block-type-foldable
    background none
    .block-header
      background none
      border none
      padding 0 0 10px
    .block-content
      display none
      background #fafafa
  &.block-type-talk-search
    margin-bottom 10px
  &.block-type-talk-friends
    margin-bottom 10px
  &.block-type-blog
    .block-header
      margin-bottom 0
      padding 0 7px 10px
      h3
        font-size 19px
        a
          color #275ec2
          text-decoration underline
    .block-content
      padding 0 7px
    footer
      button
        display inline-block
      a
        color #727a90
        margin-left 7px
  &.block-type-tags
    .search-form-wrapper
      margin-bottom 15px

.block-type-stream
  .block-content
    .latest-list
      li
        margin-bottom 10px
        color #999
      p
        a
          color #999
          &.author
            font-size 12px
            margin-right 4px
            font-weight bold
      .stream-topic
        color #666
        font-weight bold
      a
        &:hover
          color #f00
      .block-item-comments
        font-size 11px
        white-space nowrap
        i
          position relative
          top 3px
          margin-right 2px

.block-type-banner
  img
    width 100%

.block-type-donations {
  a.donate-button {
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:138px;
    padding:0 24px;
    margin:16px auto;
    background:#e64980; // pink 6
    color:#fff;
    border-radius:8px;
    font-size:16px;
    height:64px;
    line-height:64px;
    text-decoration:none;
    font-weight:bold;
    &:hover {
      background-color:#f06595; // pink 5
    }
  }
  .donations-error {
    text-align:center;
    font-style:italic;
    color:#c92a2a; // red 9
  }
  .donations-bar1, .donations-bar2 {
    display:block;
    margin:8px 0;
    width:100%;
  }
  .goal-status {
    margin-top:16px;
    .goal-status-header {
      display:flex;
      align-items:center;
      justify-content:space-between;
      font-size:14px;
      font-weight:bold;
    }
    .goal-status-bar {
      margin-top:4px;
      height:8px;
      border-radius:4px;
      background-color:#dbe4ff; // indigo 1
      position:relative;
      .goal-status-bar-progress {
        content:'';
        position:absolute;
        top:0; bottom:0; left:0;
        border-radius:4px;
        background-color:#748ffc; // indigo 4
      }
    }
    &.maxed-out {
      .goal-status-header>span:nth-child(2) {
        cursor:help;
        color:#748ffc; // indigo 4
      }
      .goal-status-bar-progress {
        box-shadow:inset 0 0 4px 1px #edf2ffdd; // indigo 0
      }
    }
    &.insufficient {
      .goal-status-header>span:nth-child(2) {
        color:#e03131; // red 8
      }
      .goal-status-bar {
        background-color:#ffc9c9; // red 2
        .goal-status-bar-progress {
          background-color:#e03131; // red 8
          animation:danger-glowing 2s infinite linear
        }
      }
    }
  }
  .donation-list {
    margin-top:16px;
    display:grid;
    grid-template-columns:1fr 1fr;
    gap:8px;
    &>div { line-height:0; }
    a {
      display:inline-block;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      height:25px;
      line-height:24px;
      font-size:13px;
      img {
        margin-right:8px;
        display:inline-block;
        vertical-align:middle;
        border-radius:4px;
      }
    }
  }
}
@keyframes danger-glowing {
  0%,100% {
    box-shadow:inset 0 0 4px 1px #fff5f5ff; // red 0
    background-color:#fa5252; // red 6
  }
  50% {
    box-shadow:inset 0 0 4px 1px #fff5f500; // red 0;
    background-color:#e03131; // red 8
  }
}


.block-loading
  background url(images/loader-circle.gif) no-repeat center top
  min-height 70px

.block-loaded
  background none
  height auto
  min-height 0
