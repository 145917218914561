.ui-autocomplete
  position absolute
  background-color #fafafa
  cursor default
  z-index 4000

.ui-menu
  list-style none
  margin 0
  display block
  float left
  .ui-menu
    margin-top -3px
  .ui-menu-item
    margin 0
    padding 0
    float left
    clear left
    width 100%
    a
      text-decoration none
      display block
      padding .2em .4em
      line-height 1.4

.ui-menu-item.ui-state-focus
  font-weight bold
  background-color #2c82fc

.ui-autocomplete-loading
  background #fafafa url(images/loader.gif) 50% 50% no-repeat
