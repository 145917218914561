article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary
  display block

audio,
canvas,
video
  display inline-block

audio
  &:not([controls])
    display none
    height 0

[hidden]
  display none

html
  font-size 100%
  -webkit-text-size-adjust 100%
  -ms-text-size-adjust 100%

html,
button,
input,
select,
textarea
  font-family Verdana, sans-serif

body
  margin 0

a
  &:focus
    outline 0

a:hover,
a:active
  outline 0

h1, h2, h3, h4, h5, h6
  font-size 13px
  line-height 1.6em
  font-weight normal
  margin 0
  font-family 'PT Sans', Arial, sans-serif

abbr[title]
  border-bottom 1px dotted

b,
strong
  font-weight bold

blockquote
  margin 1em 40px

dfn
  font-style italic

mark
  background #ff0
  color #000

p,
pre
  margin 0

pre,
code,
kbd,
samp
  font-family monospace, serif

pre

q
  quotes none

q:before,
q:after
  content ''

small
  font-size 75%

sub,
sup
  font-size 75%
  line-height 0
  position relative
  vertical-align baseline

sup
  top -0.5em

sub
  bottom -0.25em

dl,
menu,
ol,
ul
  margin 0 0

dd
  margin 0 0 0 0

menu,
ol,
ul
  padding 0 0 0 0

ul,
ol
  list-style none

img
  border 0
  -ms-interpolation-mode bicubic

svg
  &:not(:root)
    overflow hidden

figure
  margin 0

form
  margin 0

fieldset
  border none
  margin 0
  padding 0

legend
  border 0
  padding 0
  white-space normal

button,
input,
select,
textarea
  font-size 100%
  margin 0
  vertical-align baseline

button,
input
  line-height normal

button,
input[type="button"],
input[type="reset"],
input[type="submit"]
  cursor pointer
  -webkit-appearance button

button[disabled],
input[disabled]
  cursor default

input[type="checkbox"],
input[type="radio"]
  box-sizing border-box
  padding 0


input[type="search"]
  -webkit-appearance textfield
  -moz-box-sizing content-box
  -webkit-box-sizing content-box
  box-sizing content-box

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button
  -webkit-appearance none

button::-moz-focus-inner,
input::-moz-focus-inner
  border 0
  padding 0

textarea
  overflow auto
  vertical-align top

table
  border-collapse collapse
  border-spacing 0

iframe
  border none
