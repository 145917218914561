.text
  font-size 13px
  line-height 1.6em
  overflow hidden
  a
    text-decoration underline
  h4
    font-size 24px
    margin 10px 0 0
    font-weight bold
  h5
    font-size 20px
    margin 10px 0 0
    font-weight bold
  h6
    font-size 18px
    margin 10px 0 0
    font-weight bold
  pre
    max-height 400px
    margin 0
    padding 10px 14px
    background #fcfcfc
    border 1px solid #eaeaea
    border-radius 3px
    font normal 12px/1.5 Menlo, Monaco, Consolas, monospace
    overflow auto
    clear both
  code
    background #fcfcfc
    font-family Menlo, Monaco, Consolas, monospace
    border 1px solid #eaeaea
    border-radius 3px
    padding 2px 5px
  blockquote
    margin 0
    padding 15px 20px
    background #fafafa
    color #555
    clear both
    border-left 5px solid #eee
  ul
    list-style-type disc
  ol
    list-style-type decimal
  dd
    margin 0 0 0 40px
  img[align="right"]
    margin 5px 0 5px 15px
  img[align="left"]
    margin 5px 15px 10px 0
  img[align="center"]
    display block
    margin 5px auto 5px auto
  img
    &.image-center
      display block
      margin 5px auto 5px auto
  table
    border 1px solid #e1e1e1
    clear both
  p
    margin-bottom 1em

  ul, ol
    margin 0
    padding:0 0 0 1.5em;
    overflow:hidden;

  object, iframe, img
    max-width 100%
  html.resize-images & {
    img {
      object-fit:contain;
      object-position:left;
      max-width:Min(720px, 100%);
      max-height:Min(1440px, 150vh);
      @media (max-width:567px) {
        max-height:none;
      }
    }
    .spoiler img {
      max-width:100%;
      max-height:none;
    }
  }

  table
    td, th
      padding 5px 10px
      border 1px solid #e1e1e1

  .h-left
    display block
    text-align start
    float left
    padding 0.5em 1.5em 0.5em 0

  .h-center
    display block
    text-align center
    float none
    padding 0.5em 0

  .h-right
    display block
    text-align right
    float right
    padding: 0.5em 0 0.5em 1.5em

  .h-top
    vertical-align top !important

  .h-bottom
    vertical-align bottom !important
