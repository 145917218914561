.comments-header
  margin-bottom 16px;
  position relative
  display:flex;
  align-items:center;
  gap:4px 24px;
  h3
    font-size 20px
    line-height:normal;
  .subscribe
    margin-left:auto;
    font-size 11px
    color #7b848d
    input
      vertical-align:middle;
.comments-header+.notice-block
  margin-bottom:16px;

.comment-wrapper
  position relative
  .comment-wrapper
    margin-left 16px
  .reply
    margin-left 40px
  &.comment-folded
    display none
  &.collapsed > .comment-wrapper, &.collapsed > #hidden-message
    display:none;

.comment-preview
  padding 5px 10px
  margin-bottom 10px
  background #fafafa
  border 1px solid #eee
  border-radius 3px
  font-size 13px
  line-height 1.6em

#form_comment
  margin-bottom 16px

.folding
  width 13px
  height 13px
  position absolute
  top 8px
  left -16px
  background url(images/icons-synio.png) 0 -94px no-repeat
  cursor pointer
  .comment-wrapper.collapsed > &/*.folded*/
    background-position -13px -94px

.comment
  min-height:48px;
  padding:0 0 12px;
  position:relative;
  /*background #fff*/
  /*.deleted
    padding 16px 10px
    color #777
    background #f4f4f4*/
  .comment-content
    position:relative;
    border:1px solid #e7ebed;
    background-color:#f7f7f7;
    border-radius:8px;
    margin-bottom:6px;
    padding:5px 10px;
    &::before,&::after
      content:'';
      display:block;
      position:absolute;
      left:6px;
      width:0;
      height:0;
      border:6px solid transparent;
    &::before
      bottom:-12px;
      border-color:#e7ebed transparent transparent;
    &::after
      left:6px;
      bottom:-11px;
      border-color:#f7f7f7 transparent transparent;
    &.text
      blockquote
        background:#fff;
        border-color:#ccc;
        padding:5px 10px;
        margin:5px 0;
        & + br
          display:none;
  &.comment-self
    .comment-content
      background:#fff;
      /*&::before
        border-color:#e6e6bd transparent transparent;*/
      &::after
        border-color:#fff transparent transparent;
  &.comment-new
    .comment-content
      background:#edf7ed;
      /*&::before
        border-color:#dddb5c transparent transparent;*/
      &::after
        border-color:#edf7ed transparent transparent;
  &.comment-current
    .comment-content
      border-color:#58b;
      background:#eff3f9;
      &::before
        border-color:#58b transparent transparent;
      &::after
        border-color:#eff3f9 transparent transparent;
  &.comment-hidden, &.comment-deleted
    .comment-content
      border:1px dashed #ced4da; // gray 4
      background:#f8f9fa; // gray 0
      color:#868e96; // gray 6
      border-bottom-left-radius:8px;
    .comments:not(.is-admin) &
      min-height:auto;
      .comment-content
        margin-bottom:0;
        &::before,&::after
          content:none;

.comments
  margin-bottom 12px
  &.comment-list
    .comment
      margin-bottom 12px
    .comment-info
      .blog-name
        color #000
      .comment-path-topic
        color #275ec2
      .comment-path-comments
        color #000

.comment-info
  display flex
  flex-flow row
  flex-wrap wrap
  align-items center
  justify-content flex-start
  gap 4px 16px
  padding 0
  line-height 13px
  position relative
  overflow visible
  font-size 11px
  .comment-author
    font-weight bold
    color #444
    /*display flex
    align-items center*/
    &:hover
      color #2c82fc
    &.comment-topic-author::after
      content:attr(title)
      margin-left: 8px;
      color: #09f;
      font-size: 0.8em;
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
    .comment-avatar
      width 24px
      height 24px
      border-radius 4px
      margin-right 8px
      display inline-block
      vertical-align middle
  a
    &.delete
      color #ccc
  .comment-date
    color #999
    display:flex;
    align-items:center;
    gap:4px;
  .comment-link
    background transparent url(images/icons-comment.png) no-repeat -14px 0
    height 14px
    width 17px
    &:hover
      background-position -14px -14px
  .goto.goto-comment-parent
    display inline-block
    color transparent
    text-decoration none
    width 14px
    height 14px
    overflow hidden
    background transparent url(images/icons-comment.png) no-repeat 0 0
    &:hover
      cursor pointer
      background-position 0 -14px
  .go-back
    display inline-block
    color transparent
    text-decoration none
    width 14px
    height 14px
    overflow hidden
    background transparent url(images/icons-comment.png) no-repeat 0 0
    transform rotate(180deg)
    &:hover
      cursor pointer
      background-position 0 -14px

  .comment-actions
    flex:1 1 auto;
    display:flex;
    flex-wrap:wrap;
    gap:4px 16px;
    align-items:center;

  .vote
    margin 0 0 0 auto
    display flex
    flex-flow row
    align-items center
    gap:4px;
    .vote-count
      font-size 12px
      font-weight bold
      min-width 14px
      text-align center

a.comment-edit-bw
a.comment-delete
a.reply-link
.lock-edit
  display none

.comments-allowed
  .reply-link
    display inline-block

.is-admin
  .comment-hidden
    .comment-hidden-notice
      color #212529 // gray 9
  .comment-delete
    display inline-block
  .comment-deleted
    .comment-favourite
    .comment-delete
    .comment-edit-bw
    .lock-edit
      display none
    .comment-repair
      display inline-block
    .comment-deleted-notice
      color #c92a2a // red 9
      &.by-admin
        color #862e9c // grape 9

.is-admin
.is-moder
  .comment-edit-bw
  .lock-edit
    display inline-block

.comment-self
  .comment-edit-bw
  .comment-delete
    display inline-block

.reply-header
  font-size 20px
  line-height 1.3em
  &+.reply,&+.comment-preview
    margin-top:16px;

.reply
  textarea
    height 100px
    &.loader
      background #f7f7f7 url(images/loader.gif) no-repeat center
  .mceEditor
    display block
    margin-bottom 10px

.modify-notice
  font-size 10px
  font-style italic
  /*font:14px/16px monospace;
  transform:scale(-1,1);*/
  &.self-modified
    //color #69b55b
  &.adm-modified
    color #862e9c // grape 9
  &.hard-modified
    color #d5883c

.comment:not(.editable)
  .comment-save-edit-bw,
  .comment-preview-edit-bw,
  .comment-cancel-edit-bw,
  .comment-lock-edit-bw
    display none
  
.comment
  .text.preview
    margin-bottom 7px

.comment.editable
  .comment-content
    .edit-form,
    .edit-form>label
      display block
    .text
      display none
      &.preview
        display block
  .comment-actions
    .reply-link, .comment-edit-bw, .lock-edit, .comment-delete
      display none

.comment.comment-rating-negative .text
  opacity 0.5
  transition opacity ease 0.15s
.comment-bad
  .text
    img
      filter contrast(0%) brightness(159.375%)

.comment.comment-rating-negative:hover, .comment.comment-bad:hover, .comments.is-admin .comment-bad.comment-hidden
  .text
    opacity 1 !important
    img:active
      filter inherit

#quote
  background #fff
  color #333
  position absolute
  z-index 999
  display inline-block
  font 12px/15px Arial
  border 1px solid #777
  border-radius 5px
  cursor pointer
#quote:hover
  background-color #eef
#quote>i,#quote>b
  display inline-block
  background transparent url(images/markitup/edit.png) scroll -208px 0 no-repeat
  height 16px
  width 16px
#quote>b
  transform rotate(180deg)

#hidden-message
  margin 5px 0 10px
  a
    float right
    border-bottom 1px dotted #09f
    cursor pointer
