.markItUp
  a
    background transparent url(images/markitup/edit.png) no-repeat
.markItUp
  .edit-bold
    a
      background-position -0px 0px
  .edit-italic
    a
      background-position -16px 0px
  .edit-strike
    a
      background-position -32px 0px
  .edit-underline
    a
      background-position -48px 0px
  .edit-superscript
    a
      background-position -64px 0px
  .edit-subscript
    a
      background-position -80px 0px
  .edit-small
    a
      background-position -96px 0px
  .edit-h4
    a
      background-position -112px 0px
  .edit-h5
    a
      background-position -128px 0px
  .edit-h6
    a
      background-position -144px 0px
  .edit-alignment-left
    a
      background-position -160px 0px
  .edit-alignment-center
    a
      background-position -176px 0px
  .edit-alignment-right
    a
      background-position -192px 0px
  .edit-quotation
    a
      background-position -208px 0px
  .edit-code
    a
      background-position -224px 0px
  .edit-spoiler
    a
      background-position -240px 0px
  .edit-spoiler-gray
    a
      background-position -256px 0px
  .edit-list
    a
      background-position -272px 0px
  .edit-list-order
    a
      background-position -288px 0px
  .edit-list-item
    a
      background-position -304px 0px
  .edit-image
    a
      background-position -320px 0px
  .edit-video
    a
      background-position -336px 0px
  .edit-embed
    a
      background-position -352px 0px
  .edit-anchor
    a
      background-position -368px 0px
  .edit-hr
    a
      background-position -384px 0px
  .edit-br
    a
      background-position -400px 0px
  .edit-user
    a
      background-position -416px 0px
  .edit-tag
    float:right;
    a
      background-position -432px 0px
  .edit-cut
    a
      background-position -448px 0px

.markItUpEditor, .markItUpHeader
  width 100%
  box-sizing border-box

.markItUp
  width 100%

.markItUpContainer
  font 11px Verdana, Arial, Helvetica, sans-serif

.markItUpEditor
  font 12px "Courier New", Courier, monospace
  padding 5px
  height 100px
  line-height 145%
  overflow auto

.markItUpPreviewFrame
  overflow auto
  background-color #FFF
  border 1px solid #eee
  padding 10px
  height 300px
  margin 5px 0
  font 12px Tahoma, Arial, Helvetica, sans-serif
  p
    margin-bottom 18px
  blockquote
    background #fafafa
    padding 10px 15px
    color #555
    margin-bottom 5px
    clear both
  pre
    background #fafafa
    border 1px solid #dce6f0
    margin-bottom 10px
    overflow auto
    padding 5px 10px
  ul
    list-style-type disc
    margin-left 17px
  ol
    list-style-type decimal
    margin-left 22px
  img[align="right"]
    margin 4px 0 5px 15px
  img[align="left"]
    margin 4px 15px 10px 0

.markItUpFooter
  width 100%

.markItUpResizeHandle
  overflow hidden
  height 8px
  background url(images/markitup/handle.png) 50% 3px no-repeat
  cursor n-resize

.markItUpHeader
  background #eee
  padding 5px
  border-top 1px solid #c0cad5
  border-left 1px solid #c0cad5
  border-right 1px solid #c0cad5
  ul
    overflow hidden
    li
      list-style none
      float left
      position relative
      margin-right 6px
    .markItUpSeparator
      margin 0 6px 0 0
      width 1px
      height 16px
      overflow hidden
      background-color #CCC
    a
      display block
      width 16px
      height 16px
      text-indent -10000px
      background-repeat no-repeat
      margin 0
