.text
  blockquote
    border 1px dashed #BBBBBB

.link-dotted
  display inline
  color #999
  text-decoration underline dotted
  cursor pointer
  font-style normal
  &:hover,&.active:hover
    color #2c82fc
  &.active
    color:#09f;
  &.dangerous
    color:#c92a2a // red 9
  &.dangerous:hover
    color:#f00;
  &.loading
    position relative
    color transparent !important
  &.loading::after
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    margin auto
    width 8px
    height 8px
    border-radius 50%
    box-shadow:-12px 0 0 0 #334470, inset 0 0 0 8px #33447088, 12px 0 0 0 #33447000;
    animation loading-light 1.5s infinite linear


.favourite-count
  margin-left 0.4em
  color #2c82fc

.follow
  display inline-block
  width 17px
  height 17px
  vertical-align text-top
  background url(images/icons-synio.png) 0 -76px no-repeat
  cursor pointer
  &.active
    background-position -17px -76px

.pagination
  ul
    display inline-block
    line-height 1em
    margin-top 5px
    li
      display inline-block
      margin-right 5px
      span
        color #aaa
      span,a
        padding 4px 6px
      &.active
        span
          color #333
          border 1px solid #eee
          border-radius 4px
      &.prev
        span,a
        padding-left 0
      i
        opacity .5
      &.next
        margin-right 50px

.actions
  overflow hidden
  margin-bottom 10px
  font-size 11px
  li
    float left
    margin-right 10px

.toolbar
  position fixed
  top 35%
  right 0
  z-index 20
  display none
  section
    width 25px
    padding 8px
    overflow hidden
    line-height 14px
    background #fff
    border-style solid
    border-color #ccc
    margin-bottom 10px
    border-radius 10px 0 0 10px
    border-width 1px 0 1px 1px
    i
      display block
      width 25px
      height 25px
  .toolbar-admin
    i
      background url(images/icons-synio.png) -268px -23px no-repeat
      &:hover
        background-position -268px -48px
      &:active
        background-position -268px -73px
  .toolbar-update
    .update-comments
      background url(images/update.gif) no-repeat
      cursor pointer
      &:hover
        opacity: .75
      &.active
        background-position -25px 0
    .new-comments
      padding 5px 0 3px
      font-size:12px;
      font-weight:bold;
      color #51983c
      text-align center
      text-decoration none
      /*width 25px*/
      margin:12px 0 0 0;
      cursor pointer
      &:hover
        opacity: .75
  .toolbar-topic
    width 19px
    padding 11px
    a
      &.toolbar-topic-prev
        i
          display block
          width 19px
          height 32px
          background url(images/icons-synio.png) -211px -23px no-repeat
          &:hover
            background-position -230px -23px
          &:active
            background-position -249px -23px
      &.toolbar-topic-next
        i
          display block
          width 19px
          height 32px
          background url(images/icons-synio.png) -211px -55px no-repeat
          &:hover
            background-position -230px -55px
          &:active
            background-position -249px -55px
  .toolbar-scrollup
    display none
    i
      background url(images/icons-synio.png) -294px -23px no-repeat
      &:hover
        background-position -294px -48px
      &:active
        background-position -294px -73px

.user-list-avatar
  overflow hidden
  li
    width 48px
    overflow hidden
    float left
    margin-right 12px
    margin-bottom 12px
    text-align center
    font-size 11px
    a
      white-space nowrap
    img
      margin-bottom 2px
      display block

.user-list-mini
  li
    padding 0 25px 4px 5px
    margin-bottom 0
    position relative
    &.grayed
      opacity 0.8
      a
        color #868e96 // gray 6
    img
      width 17px
      height 17px
      position relative
      top 4px
      margin-right 6px
    input
      position relative
      top 2px
    i
      position absolute
      top 4px
      right 5px
      cursor pointer
      display none
    &:hover
      background #fcfce9
      i
        display block

.tag-cloud
  text-align center
  width 100%
  li
    display inline
    margin-right 5px
    line-height 22px
    a
      color #727a90
      &:hover
        color #f00
    .tag-size-1
      font-size 12px
    .tag-size-2
      font-size 12px
    .tag-size-3
      font-size 14px
    .tag-size-4
      font-size 14px
    .tag-size-5
      font-size 18px
    .tag-size-6
      font-size 18px
    .tag-size-7
      font-size 20px
    .tag-size-8
      font-size 20px
    .tag-size-9
      font-size 22px
    .tag-size-10
      font-size 22px

.item-list
  border-top 1px solid #eee
  li
    min-height 51px
    padding 7px 0 10px 58px
    border-bottom 1px solid #eee
    position relative
    p
      padding-top 2px
      font-size 11px
    a
      text-decoration none
    .avatar
      position absolute
      top 10px
      left 0
      margin-right 10px
    .author
      font-weight bold
      color #333
    .blog-name
      font-weight bold

.wrapper
  overflow hidden

.wrapper-content
  h3
    font-size 15px
    margin-bottom 10px
    font-family Verdana, sans-serif
  &.wrapper-content-dark
    padding 15px 20px
    background #f1f7fd

.system-message-error
  background #f0c8c8
  color #b22626
  padding 10px 15px
  margin-bottom 15px

.system-message-notice
  background #dafad8
  color #4bb23b
  padding 10px 15px
  margin-bottom 15px

.notice-empty
  color #aaa

.notice-block {
  padding:15px 16px;
  border:1px dashed #ced4da; // gray 4
  border-left:5px solid #adb5bd; // gray 5
  background:#f8f9fa; // gray 0
  color:#343a40; // gray 8
  &.warning {
    border:1px dashed #ffd43b; // yellow 4
    border-left:5px solid #fcc419; // yellow 5
    background:#fff9db; // yellow 0
  }
}

#global-site-notification {
  position:relative;
  max-width:640px; margin:0 auto 24px auto;
  padding:15px 16px;
  border:1px dashed #ced4da; // gray 4
  border-left:5px solid #adb5bd; // gray 5
  background:#f8f9fa; // gray 0
  color:#343a40; // gray 8
  &.info {
    border:1px dashed #748ffc; // indigo 4
    border-left:5px solid #5c7cfa; // indigo 5
    background:#edf2ff; // indigo 0
  }
  &.warning {
    border:1px dashed #ffd43b; // yellow 4
    border-left:5px solid #fcc419; // yellow 5
    background:#fff9db; // yellow 0
  }
  &.success {
    border:1px dashed #69db7c; // green 4
    border-left:5px solid #51cf66; // green 5
    background:#ebfbee; // green 0
  }
  .close {
    position:absolute; top:15px; right:16px;
    cursor:pointer; font-size:1.2em;
  }
  .title,.details {
    font-size:11pt; line-height:1.6em; padding-right:1.6em;
  }
  .title + .details {
    padding-right:0;
  }
  .title {
    font-weight:bold;
  }
  .details a.read-more::after {
    content: '\2192';
    position: absolute;
    padding-left: 4px;
  }
}

.loader
  background #fafafa url(images/loader.gif) 50% 50% no-repeat

.ls-user
  display inline-block

.user:not(.has-avatar):before, .ls-user:not(.has-avatar):before
  content ''
  display inline-block
  width 12px
  height 12px
  margin-right 4px
  background url(images/icons-synio.png) -165px -74px no-repeat
  vertical-align middle
  position relative
  top -1px

.user.has-avatar, .ls-user.has-avatar
  img
    vertical-align text-bottom
    width 14px
    height 14px
    margin-right 4px

.user.undefined:before, .ls-user.undefined:before
  width 14px
  height 14px

.user.undefined, .ls-user.undefined
  pointer-events none
  font-weight normal
  font-style italic
  text-decoration none

.user
  .icon-user
    margin-right 3px

.username.inactive, .user.inactive
  color #aaa !important

.user-avatar
  img
    width 16px
    height 16px
    vertical-align middle
    margin-right 1px
  a
    color #666
  &.user-avatar-n
    a
      color #3F8AD7
      font-size 12px

.counter
  &.exceeded
    color #f00

.page-header-menu
  overflow hidden
  margin-bottom 10px
  .page-header
    float left
    margin-right 20px
    margin-bottom 10px
  .nav
    &.nav-pills
      margin-top 5px
      float left

.blog-list-info
  width 19px
  height 19px
  display inline-block
  background url(images/icons-synio.png) 0 -107px no-repeat
  cursor pointer

.blog-list-info:hover,
.blog-list-info.active
  background-position -19px -107px

.vote-list-box
  position:absolute;
  z-index:5000;
  transition-duration:0.5s;
  transition-property:opacity,filter;
  opacity:1;
  font-size:12px;
  line-height:18px;
  box-sizing:border-box;
  padding:8px;
  max-width:640px;
  max-height:320px;
  min-width:256px;
  min-height:34px;
  width:max-content;
  overflow:hidden hidden;
  scrollbar-width:thin;
  border:1px solid #ced4da; // gray 4
  border-radius:8px;
  background-color:#f8f9fa; // gray 0
  box-shadow:0 0 4px 0 #0005;
  display:grid;
  grid-template-columns:minmax(0,1fr) repeat(2,auto);
  align-items:center;
  gap:4px 16px;
  color:#343a40; // gray 8
  @media (max-width:567px)
    max-width:80vw;
  &.hidden
    opacity:0;
  &.for-comment
    right:20px;
    bottom:24px;
    /*border-bottom-right-radius:0;*/
  &.for-topic
    left:20px;
    /*border-top-left-radius:0;*/
    @media (max-width:567px)
      left:-40px;
  &.for-blog
    right:20px;
    top:27px;
    /*border-top-right-radius:0;*/
  &.for-user
    right:20px;
    top:24px;
    /*border-top-right-radius:0;*/
    @media (max-width:567px)
      right:auto;
      left:-90px/*20px*/;
    /*border-top-right-radius:8px;
      border-top-left-radius:0;*/
  &::after {
    content:'';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    width:8px;
    height:8px;
    border-radius:50%;
    box-shadow:-12px 0 0 0 #334470, inset 0 0 0 8px #33447088, 12px 0 0 0 #33447000;
    animation:loading-light 1.5s infinite linear;
    opacity:0;
    transition:opacity 0.2s ease;
  }
  &.loading {
    color:transparent !important;
  }
  &.loading::after {
    opacity:1;
  }

  .vote-list-item
    display:contents;
    .vli-profile
      max-width:50vw;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      img
        width:16px;
        height:16px;
    .vli-time,.vli-vote
      text-align:right;
    .vli-time
      color:#686F77; // gray 6.5
      font-size:11px;
    .vli-vote::before
      content attr(data-value)
      font-weight bolder
    .vli-vote[data-value="0"]::before
      color black
    .vli-vote[data-value^="+"]::before
      color #390
    .vli-vote[data-value^="−"]::before
      color #f00
  .vote-list-separator
    grid-column:1 / span 3;
    max-width:240px;
    text-align:center;
    justify-self:center;
    font-size:12px;
    font-style:italic;
    color:#495057; // gray 7

/*@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
  }
  [title]:focus::after {
    content:attr(title);
    position:absolute;
    top:90%; left:10%
    width:fit-content;
    padding:2px 4px;
    background-color:#fff;
    border:1px solid #333;
    color:#333;
    font-size:12px; line-height:16px;
  }
}*/

@keyframes loading-light {
    0%,100% {
        box-shadow:-12px 0 0 0 #334470, inset 0 0 0 8px #33447088, 12px 0 0 0 #33447000;
    }
    20% {
        box-shadow:-12px 0 0 0 #33447088, inset 0 0 0 8px #334470, 12px 0 0 0 #33447088;
    }
    40% {
        box-shadow:-12px 0 0 0 #33447000, inset 0 0 0 8px #33447088, 12px 0 0 0 #334470;
    }
    60% {
        box-shadow:-12px 0 0 0 #33447000, inset 0 0 0 8px #33447000, 12px 0 0 0 #33447088;
    }
    80% {
        box-shadow:-12px 0 0 0 #33447088, inset 0 0 0 8px #33447000, 12px 0 0 0 #33447000;
    }
}
@keyframes loading-dark {
    0%,100% {
        box-shadow -12px 0 0 0 #fafafa, inset 0 0 0 8px #fafafa88, 12px 0 0 0 #fafafa00;
    }
    20% {
        box-shadow -12px 0 0 0 #fafafa88, inset 0 0 0 8px #fafafa, 12px 0 0 0 #fafafa88;
    }
    40% {
        box-shadow -12px 0 0 0 #fafafa00, inset 0 0 0 8px #fafafa88, 12px 0 0 0 #fafafa;
    }
    60% {
        box-shadow -12px 0 0 0 #fafafa00, inset 0 0 0 8px #fafafa00, 12px 0 0 0 #fafafa88;
    }
    80% {
        box-shadow -12px 0 0 0 #fafafa88, inset 0 0 0 8px #fafafa00, 12px 0 0 0 #fafafa00;
    }
}
@keyframes loading-variable {
    0%,100% {
        box-shadow -12px 0 0 0 var(--c), inset 0 0 0 8px var(--c2), 12px 0 0 0 var(--c3);
    }
    20% {
        box-shadow -12px 0 0 0 var(--c2), inset 0 0 0 8px var(--c), 12px 0 0 0 var(--c2);
    }
    40% {
        box-shadow -12px 0 0 0 var(--c3), inset 0 0 0 8px var(--c2), 12px 0 0 0 var(--c);
    }
    60% {
        box-shadow -12px 0 0 0 var(--c3), inset 0 0 0 8px var(--c3), 12px 0 0 0 var(--c2);
    }
    80% {
        box-shadow -12px 0 0 0 var(--c2), inset 0 0 0 8px var(--c3), 12px 0 0 0 var(--c3);
    }
}
