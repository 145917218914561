#notifier
  width 250px
  position fixed
  top 10px
  right 10px
  font-size 11px
  line-height 16px
  z-index 9999
  .n-box
    position relative
    padding 15px 20px 16px
    margin-bottom 10px
    color #fff
    background #000
    -moz-border-radius 10px
    -webkit-border-radius 10px
    border-radius 10px
    -webkit-box-shadow 0 0 5px rgba(0,0,0,.3)
    box-shadow 0 0 5px rgba(0,0,0,.3)
    opacity 0.9
    cursor pointer
    h3
      color #fff
      font-size 12px
      font-weight bold
      margin-bottom 3px
      font-family Verdana, sans-serif
    p
      margin 0
    &.n-notice
      background #107306
      color #fff
    &.n-error
      background #850505
      color #fff
