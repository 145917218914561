[class^="icon-"],
[class*=" icon-"]
  display inline-block
  vertical-align text-top
  width 14px
  height 14px
  line-height 14px
  background url(images/icons.png) no-repeat

[class^="icon-synio-"],
[class*=" icon-synio-"]
  background url(images/icons-synio.png) no-repeat

.icon-white
  background-image url(images/icons-white.png)

.icon-glass
  background-position 0      0

.icon-music
  background-position -24px  0

.icon-search
  background-position -48px  0

.icon-envelope
  background-position -72px  0

.icon-heart
  background-position -96px  0

.icon-star
  background-position -120px 0

.icon-star-empty
  background-position -144px 0

.icon-user
  background-position -168px 0

.icon-film
  background-position -192px 0

.icon-th-large
  background-position -216px 0

.icon-th
  background-position -240px 0

.icon-th-list
  background-position -264px 0

.icon-ok
  background-position -288px 0

.icon-remove
  background-position -312px 0

.icon-zoom-in
  background-position -336px 0

.icon-zoom-out
  background-position -360px 0

.icon-off
  background-position -384px 0

.icon-signal
  background-position -408px 0

.icon-cog
  background-position -432px 0

.icon-trash
  background-position -456px 0

.icon-home
  background-position 0      -24px

.icon-file
  background-position -24px  -24px

.icon-time
  background-position -48px  -24px

.icon-road
  background-position -72px  -24px

.icon-download-alt
  background-position -96px  -24px

.icon-download
  background-position -120px -24px

.icon-upload
  background-position -144px -24px

.icon-inbox
  background-position -168px -24px

.icon-play-circle
  background-position -192px -24px

.icon-repeat
  background-position -216px -24px

.icon-refresh
  background-position -240px -24px

.icon-list-alt
  background-position -264px -24px

.icon-lock
  background-position -287px -24px

.icon-flag
  background-position -312px -24px

.icon-headphones
  background-position -336px -24px

.icon-volume-off
  background-position -360px -24px

.icon-volume-down
  background-position -384px -24px

.icon-volume-up
  background-position -408px -24px

.icon-qrcode
  background-position -432px -24px

.icon-barcode
  background-position -456px -24px

.icon-tag
  background-position 0      -48px

.icon-tags
  background-position -25px  -48px

.icon-book
  background-position -48px  -48px

.icon-bookmark
  background-position -72px  -48px

.icon-print
  background-position -96px  -48px

.icon-camera
  background-position -120px -48px

.icon-font
  background-position -144px -48px

.icon-bold
  background-position -167px -48px

.icon-italic
  background-position -192px -48px

.icon-text-height
  background-position -216px -48px

.icon-text-width
  background-position -240px -48px

.icon-align-left
  background-position -264px -48px

.icon-align-center
  background-position -288px -48px

.icon-align-right
  background-position -312px -48px

.icon-align-justify
  background-position -336px -48px

.icon-list
  background-position -360px -48px

.icon-indent-left
  background-position -384px -48px

.icon-indent-right
  background-position -408px -48px

.icon-facetime-video
  background-position -432px -48px

.icon-picture
  background-position -456px -48px

.icon-pencil
  background-position 0      -72px

.icon-map-marker
  background-position -24px  -72px

.icon-adjust
  background-position -48px  -72px

.icon-tint
  background-position -72px  -72px

.icon-edit
  background-position -96px  -72px

.icon-check
  background-position -144px -72px

.icon-move
  background-position -168px -72px

.icon-step-backward
  background-position -192px -72px

.icon-fast-backward
  background-position -216px -72px

.icon-backward
  background-position -240px -72px

.icon-play
  background-position -264px -72px

.icon-pause
  background-position -288px -72px

.icon-stop
  background-position -312px -72px

.icon-forward
  background-position -336px -72px

.icon-fast-forward
  background-position -360px -72px

.icon-step-forward
  background-position -384px -72px

.icon-eject
  background-position -408px -72px

.icon-chevron-left
  background-position -432px -72px

.icon-chevron-right
  background-position -456px -72px

.icon-plus-sign
  background-position 0      -96px

.icon-minus-sign
  background-position -24px  -96px

.icon-remove-sign
  background-position -48px  -96px

.icon-ok-sign
  background-position -72px  -96px

.icon-question-sign
  background-position -96px  -96px
  width 23px
  height 23px
  background url(images/icons-synio.png) -138px 0 no-repeat

.icon-info-sign
  background-position -120px -96px

.icon-screenshot
  background-position -144px -96px

.icon-remove-circle
  background-position -168px -96px

.icon-ok-circle
  background-position -192px -96px

.icon-ban-circle
  background-position -216px -96px

.icon-arrow-left
  background-position -240px -96px

.icon-arrow-right
  background-position -264px -96px

.icon-arrow-up
  background-position -289px -96px

.icon-arrow-down
  background-position -312px -96px

.icon-resize-full
  background-position -360px -96px

.icon-resize-small
  background-position -384px -96px

.icon-plus
  background-position -408px -96px

.icon-minus
  background-position -433px -96px

.icon-asterisk
  background-position -456px -96px

.icon-exclamation-sign
  background-position 0      -120px

.icon-gift
  background-position -24px  -120px

.icon-leaf
  background-position -48px  -120px

.icon-fire
  background-position -72px  -120px

.icon-eye-open
  background-position -96px  -120px

.icon-eye-close
  background-position -120px -120px

.icon-warning-sign
  background-position -144px -120px

.icon-plane
  background-position -168px -120px

.icon-calendar
  background-position -192px -120px

.icon-random
  background-position -216px -120px

.icon-comment
  background-position -240px -120px

.icon-magnet
  background-position -264px -120px

.icon-chevron-up
  background-position -288px -120px

.icon-chevron-down
  background-position -313px -119px

.icon-retweet
  background-position -336px -120px

.icon-shopping-cart
  background-position -360px -120px

.icon-folder-close
  background-position -384px -120px

.icon-folder-open
  background-position -408px -120px

.icon-resize-vertical
  background-position -432px -119px

.icon-resize-horizontal
  background-position -456px -118px

.icon-contact
  width 16px
  height 16px
  background url(images/icons-contact.png) no-repeat

.icon-contact-default
  background-position 0 0

.icon-contact-mail
  background-position -20px 0

.icon-contact-icq
  background-position -40px 0

.icon-contact-jabber
  background-position -60px 0

.icon-contact-phone
  background-position -80px 0

.icon-contact-skype
  background-position -100px 0

.icon-contact-vkontakte
  background-position -120px 0

.icon-contact-twitter
  background-position -140px 0

.icon-contact-facebook
  background-position -160px 0

.icon-contact-odnoklassniki
  background-position -180px 0

.icon-contact-www
  background-position -200px 0

.icon-ok-green
  width 18px
  height 20px
  background url(images/icons-synio.png) -479px -2px no-repeat

.icon-synio-arrow-up
  width 9px
  height 9px
  background-position -24px -24px

.icon-synio-arrow-down
  width 9px
  height 9px
  background-position -33px -24px

.icon-synio-star-green
  width 12px
  height 11px
  background-position 0 -23px

.icon-synio-star-blue
  width 11px
  height 11px
  background-position 0 -37px

.icon-synio-star-white
  width 11px
  height 11px
  background-position 0 -51px

.icon-synio-star-yellow
  width 11px
  height 11px
  background-position 0 -65px

.icon-synio-share-blue
  width 11px
  height 11px
  background-position -12px -37px

.icon-synio-share-white
  width 11px
  height 11px
  background-position -12px -51px

.icon-synio-rating
  width 11px
  height 11px
  background-position -12px -23px

.icon-synio-arrows
  width 11px
  height 11px
  background-position -22px -65px

.icon-synio-comments-green
  width 11px
  height 11px
  background-position -46px -23px

.icon-synio-comments-green-filled
  width 11px
  height 11px
  background-position -46px -51px

.icon-synio-comments-blue
  width 11px
  height 11px
  background-position -46px -37px

.icon-synio-comments-small
  width 11px
  height 11px
  background-position -28px -51px

.icon-synio-tags
  width 15px
  height 11px
  background-position -26px -37px

.icon-synio-actions-edit
  width 16px
  height 16px
  background-position -115px -23px

.icon-synio-actions-delete
  width 16px
  height 16px
  background-position -131px -23px

.icon-synio-actions-draft
  width 16px
  height 16px
  background-position -115px -39px

.icon-synio-topic-private
  width 23px
  height 23px
  background-position -230px 0

.icon-synio-topic-draft
  width 23px
  height 23px
  background-position -253px 0

.icon-synio-topic-link
  width 23px
  height 23px
  background-position -276px 0

.icon-synio-close
  width 16px
  height 16px
  background-position -131px -39px

.icon-synio-link
  width 11px
  height 11px
  background-position -11px -65px

.icon-synio-add-friend
  width 16px
  height 16px
  background-position -147px -23px

.icon-synio-send-message
  width 16px
  height 16px
  background-position -163px -23px

.icon-synio-new-message
  width 16px
  height 16px
  background-position -163px -55px

.icon-synio-join
  width 16px
  height 16px
  background-position -179px -23px

.icon-synio-search
  width 16px
  height 16px
  background-position -147px -55px

.icon-synio-submit
  width 16px
  height 16px
  background-position -115px -55px

.icon-synio-arrow-left
  width 16px
  height 16px
  background-position -115px -71px

.icon-synio-arrow-right
  width 16px
  height 16px
  background-position -131px -71px

.icon-synio-remove
  width 24px
  height 24px
  background-position -368px 0
  vertical-align middle
  position relative
  top -2px
  &:hover
    background-position -392px 0

.icon-synio-vote-info-down
  width 16px
  height 16px
  background-position -67px -71px

.icon-synio-vote-info-up
  width 16px
  height 16px
  background-position -83px -71px

.icon-synio-vote-info-zero
  width 16px
  height 16px
  background-position -99px -71px

.icon-synio-vote-info-view
  width 16px
  height 16px
  background-position -179px -39px

.semi-private
  display inline-block
  width 23px
  height 23px
  background transparent url(images/half-lock.png) no-repeat 0 0
  position relative
  top -1px
  vertical-align middle

i
  &.icon-synio-topic-private
    background transparent url(images/lock.png) no-repeat 0 0
