.vote-topic {
    overflow:hidden;
    font-size:18px;
    display:flex;
    flex-flow:row;
    align-items:center;
    gap:4px;
}
.stream-voted { font-weight:bold; }

.vote-up,
.vote-down,
.stream-voted-up,
.stream-voted-down {
    height:24px;
    width:20px;
    color:transparent;
    display:inline-block;
    vertical-align:middle;
    background:no-repeat center center;
}

.vote-up,
.stream-voted-up {
    background-image:url(../images/up.png);
    opacity:0.8;
}

.vote-down,
.stream-voted-down {
    background-image:url(../images/down.png);
}

.vote-count {
    --c:#949aa1;
    --c2:#949aa188;
    --c3:#949aa100;
    color:var(--c);
    padding:0 4px;
    line-height:18px;
    font-size:18px;
    position:relative;
    transition:color 0.2s ease;
}
.vote-count::after {
    content:'';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    width:8px;
    height:8px;
    border-radius:50%;
    box-shadow:-12px 0 0 0 var(--c), inset 0 0 0 8px var(--c2), 12px 0 0 0 var(--c3);
    animation:loading-variable 1.5s infinite linear;
    opacity:0;
    transition:opacity 0.2s ease;
}
.vote-count.loading {
    color:transparent !important;
}
.vote-count.loading::after {
    opacity:1;
}

.vote-info-enabled .vote-count.vote-item {
    cursor:help;
}
.vote-info-enabled .vote-count[data-count="0"] {
    cursor:default;
}
.vote-info-enabled .vote-count[data-count]:not([data-count="0"]):not([data-query-state="query"]) {
    cursor:help;
}

/*.vote-info-enabled*/.vote-count-mixed .vote-count {
    --c:#212529;
    --c2:#21252988;
    --c3:#21252900;
}

.vote-count-positive .vote-count {
    --c:#5fa459;
    --c2:#5fa45988;
    --c3:#5fa45900;
}

.vote-count-negative .vote-count {
    --c:#da4242;
    --c2:#da424288;
    --c3:#da424200;
}

.vote-up, .vote-down {
    filter:contrast(0%) brightness(150%);
}

.vote-enabled .vote-up,
.vote-enabled .vote-down {
    filter:contrast(50%) brightness(150%);
    opacity:0.75;
}
.vote-enabled .vote-up:hover,
.vote-enabled .vote-down:hover {
    filter:none;
    opacity:1;
}
.vote-enabled .vote-item { cursor:pointer; }

.voted-up .vote-up,
.voted-down .vote-down,
.stream-voted-up,
.stream-voted-down {
    filter:none;
    opacity:1;
    cursor:default;
}
