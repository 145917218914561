/*@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}*/

body
  font-size 12px
  font-family Arial, sans-serif
  line-height 18px
  color #333
  background url(images/body-bg.png) repeat scroll 0 0 #FFFFFF

br
  &.clear
    clear both

a
  color #09f
  &:hover
    color #2c82fc
  &.dangerous
    color:#c92a2a // red 9
  &.dangerous:hover
    color:#f00;

.user, .ls-user
  font-weight bold
  color:#343a40; // gray 8
  &:visited
    color:#343a40; // gray 8
  &:hover
    color:#2c82fc;

img,iframe
  max-width 100%

.text
  a
    &:visited
      color #1D2273


.spoiler
  display block
  margin:8px 0;
  & + br
    display none
  &>.spoiler-title
    background:#f8f9fa // gray 0
    border:1px solid #ced4da; // gray 4
    border-radius:4px;
    padding:4px 8px;
    cursor pointer
    display:inline-block /*inline-flex*/;
    /*align-items:center;
    &::after
      content:'';
      height:0;
      margin:0.5em 0 0 2em;
      border:0.4em solid;
      border-color:#868e96 transparent transparent transparent; // gray 6*/
    .comment:not(.comment-self) &
      background:#fff;
  &.spoiler-media>.spoiler-title
    box-shadow 0 0 2px 1px rgba(168,255,127,0.8)
  &>.spoiler-body
    display none
    padding-top:8px;
    .spoiler
      margin:8px;
  &.active
    &>.spoiler-title
      /*&::after
        margin-top:-0.5em;
        border-color:transparent transparent #868e96 transparent; // gray 6*/
    &>.spoiler-body
      display:block;

.spoiler-gray
  background-color #CCC
  color #CCC
  a,a:visited,a.ls-user[href]
    color #ccc
  .ls-user::before
    opacity 0
  &:hover
    background-color transparent
    color #666
    a
      background-color transparent
      color #0099FF
    a.ls-user
      color #555
    .ls-user::before
      opacity inherit
    img
      filter inherit
      -webkit-filter inherit
  &:not(:hover)
    img
      filter contrast(0%) brightness(159.375%)
      -webkit-filter contrast(0%) brightness(159.375%)

h1, h2, h3, h4, h5, h6
  color #444

textarea
  &.markItUpEditor
    height 300px

div
  &.markItUpHeader
    padding 5px 0

.markItUpFooter
  padding 0
  height 100%

.comment-content
  .text
    img
      vertical-align bottom

.generator
  .list
    .list-item
      padding-left 0

#popup-password
  margin-bottom 12px

#running-ponies
  height:60px;
  margin-bottom:20px;
  overflow:hidden;
  display:flex;
  align-items:flex-end;
  justify-content:space-between;
  white-space:nowrap;
#running-ponies > *:nth-child(n+6)
  display:none;

#widemode
  background-color #FFFFFF
  border-radius 10px 0 0 0
  bottom 0
  padding 7px 1px 7px 10px
  position fixed
  right 0
  z-index 100
  border 1px solid #CCCCCC
  display flex
  justify-content flex-end
  flex-flow row
  align-items center
  a
    border 1px solid #ccc
    border-radius 5px
    height 25px
    line-height 25px
    text-decoration none!important
    color: #7F8993
    font-weight bold
    margin-right 10px
    cursor pointer
    background #f0f2f5
    background linear-gradient(to top, #f0f2f5 0%, #fefefe 100%)
    i
      display  inline-block
      width 25px
      height 25px
      background-image url('images/arrow.png')
    &:hover
      color #fff
      background #4ec4ff
      background linear-gradient(to top, #4ec4ff 0%, #22b2fe 100%)
      border-color #27ace8
      i
        background-position -25px 0
  .switch-label
    display none
  #despoil
    padding 0 10px
    width auto
  #widemode-switch
    width auto
    span::before,span::after
      content ' '
      width 25px
      height 25px
      float left
      background transparent url('images/arrow.png')
    span::after
      float right
    &:hover
      span::before,span::after
        background-position -25px 0
  #down-switch
    i
      transform rotate(180deg)
  .widemode.big-screen::after,
  .normalmode.big-screen::before,
  .normalmode.small-screen::after,
  .normalmode.small-screen::before
    transform rotate(90deg)
  .widemode.big-screen::before,
  .normalmode.big-screen::after,
  .widemode.small-screen::after,
  .widemode.small-screen::before
    transform rotate(270deg)


#up-switch, #down-switch
  display inline-block
  width 25px
  height 25px
  background url(images/icons-synio.png) no-repeat scroll -294px -23px transparent

#down-switch
  background-position -294px -148px
  &:hover
    background-position -294px -123px

#up-switch
  &:hover
    background-position -294px -48px

h2
  &.page-header
    color #333
    font-size 27px
    line-height 1em
    font-weight normal
    margin-bottom 10px
    i
      vertical-align middle
  &.header-table
    margin-bottom 10px
    font-size 22px
    font-weight normal
    a
      color #275ec2
    span
      color #949aa1

#header-menu
  .new-messages
    position relative
    color #fff
    padding 3px
    border-radius 50%
    background-color green
    font-weight bold
    font-size 14px
    text-decoration none
    i
      position relative
      top -1px
  .auth
    position absolute
    right 20px
    top 15px
    margin auto 0
    font-size 18px
    flex-wrap nowrap
    justify-content flex-end
    li
      margin 10px 15px 0 0
  .dropdown-user
    display inline-block
    position absolute
    right 24px
    top 50%
    margin -35px 0 0 0
    padding 0 80px 0 0
    min-height 70px
    text-align right
    line-height 22px
    .dropdown-user-menu
      justify-content flex-end
    .dropdown-user-trigger
      text-decoration underline
      cursor pointer
    .username
      font-size 15px
      text-decoration underline
      white-space nowrap
      display inline-block
      font-weight bold
      &:hover
        color #aaa
    .avatar
      position absolute
      right 0
      border 1px solid #CCC
      border-radius 5px
      width 64px
      height 64px

.mozilla
  #header-menu
    .new-messages
      i
        top 0

.dropdown-user-menu
  list-style-type none
  li
    display inline-block
    margin-left 5px
    &.active a
      color #000
      text-decoration none
    &.item-stat
      .rating
        &.negative
          color #666
      .strength
        color #666
        margin-right 8px

  /*.dropdown-user-menu
    li
      &.item-stat
        .rating
          color #666
      &.item-messages
        .new
          position absolute
          top 15px
          right 7px
          cursor pointer
          color #fff
          border-radius 15px
          border 2px solid #eabe21
          line-height 1em
          padding 2px 7px 3px*/

.button-write
  color #DA5252

.block-type-pseudomenu
  ul
    font-size 18px
    list-style-type none
    li
      padding-right 5px
      display inline-block

.block
  &.block-type-search
    padding 8px

.search-header
  display inline-block
  width 100%
  .search-header-form
    position relative
    .input-text
      padding 4px 25px 4px 5px
      width 100%
    .input-submit
      position absolute
      top 5px
      right 5px
      width 16px
      height 16px
      border none
      background url(images/icons-synio.png) -115px -55px no-repeat

.stat-performance
  width auto
  margin 0 auto 20px
  padding 15px 20px
  background #fff
  border-top 1px solid #eee
  border-bottom 1px solid #eee
  table
    td
      width 200px
      vertical-align top
  h4
    font-weight bold

.userfeed-get-more
  display block
  width 90%
  margin 0 auto
  text-align center
  height 2.5em
  padding-top 1em
  &:hover
    background #f0f7fd

.stream-get-more
  margin-top 50px
  border-radius 5px
  display block
  text-align center
  padding 12px 0
  color #258fa7
  text-decoration none
  background #eefbfc
  background linear-gradient(to top,  #eefbfc 0%,#d3edf8 100%)
  cursor: pointer
  &:hover
    background #eefbfc
    color #258fa7
  &.loader
    background-image url(images/loader.gif)
    background-repeat no-repeat
    background-position 50% 50%

.stream_loading, .userfeed_loading
  background #f7f7f7 url(images/loader.gif) no-repeat center

.stream-comment-preview
  position relative
  padding 5px 10px 6px
  background #fefef1
  color #363636
  margin-top 13px
  font-size 11px
  border-radius 3px
  -webkit-box-shadow 0 2px 4px #e8e8c0 inset
  box-shadow 0 2px 4px #e8e8c0 inset
  &:after
    display block
    content ""
    position absolute
    top -12px
    left 15px
    width 0
    height 0
    border-width 6px
    border-color transparent transparent #e8e8c0 transparent
    border-style solid

.stream-list
  margin 0 0 20px
  li
    &.stream-item
      min-height 36px
      padding 0 0 16px 46px
      position relative
      font-size 14px
      &:last-child
        border none
      .info
        color #b9c2c2
        font-size 11px
        line-height 1em
        margin-bottom 5px
        a
          color #275ec2
        .date
          color #818189
      .avatar
        position absolute
        top 0
        left 0
        width 36px
        height 36px
      .user-avatar
        margin-left 3px
    &.stream-header-date
      font-size 16px
      color #999
      margin-top 10px
      margin-bottom 4px
      padding-bottom 5px

.content
  h2
    font-size 2em

.userfield-list
  max-width 450px
  li
    background #eee
    margin-bottom 1px
    padding 10px 10px
    overflow hidden
    line-height 12px
    color #777
    .userfield-actions
      float right
    .userfield_admin_name
      font-weight bold
      color #333

.userfield-add
  text-decoration none
  border-bottom 1px dashed #70AAE0
  &:hover
    color #333
    border-color #333

.talk-search
  padding 8px 15px
  background #f8fbfe url(images/message-search.png) no-repeat 96% 180px
  border-radius 5px
  margin-bottom 15px
  header
    position relative
    min-height 27px
    .close
      position absolute
      top 3px
      right 0
      font-size 11px
      cursor pointer
  .talk-search-content
    display none
    padding-top 15px
    border-top 1px solid #fff
    p
      margin-bottom 25px
  &.opened
    header
      border-bottom 1px solid #e8eef5
      padding-bottom 15px
    .talk-search-content
      display block
  &.talk-friends
    header
      .close
        left 0
        right auto
    .friend-list
      margin-bottom 15px
      font-weight bold
      li
        margin-bottom 5px

.talk-blacklist-form
  padding 15px
  background #f8fbfe
  border-radius 5px
  margin-bottom 15px

.avatar-edit
  margin-bottom 15px
  img
    vertical-align bottom
  label
    margin-top 10px

.drafts
  float right
  color #275ec2

.content-error
  h2
    &.page-header
      font-weight bold
      font-size 30px
      margin-bottom 35px
  a
    color #727a90
    &:hover
      color #f00

.link-dotted
  &.help-link
    font-size 11px
    color #000
    border-color #000

.donate
  text-align center
  margin-bottom 22px

.version div
  padding 0 1em
  display inline-block

.image-art-credits
  font-size 10px
  line-height 12px
  color #999
  margin-top -12px
  margin-bottom 20px
  &.under-text
    margin-top 20px
    margin-bottom 0
  & a
    color #999
