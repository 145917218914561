@media print
  *
    background transparent !important
    color black !important
    text-shadow none !important
    filter none !important
    -ms-filter none !important
    font-family Arial, sans-serif !important

  body, .text
    font-size 14pt

  a, a:visited
    text-decoration underline

  pre, blockquote
    border 1px solid #999
    page-break-inside avoid

  thead
    display table-header-group

  tr, img
    page-break-inside avoid

  img
    max-width 100% !important

  h1
    font-size 30pt !important
    font-weight bold !important
    a
      text-decoration none !important

  h4
    font-size 26pt !important

  h5
    font-size 23pt !important

  h6
    font-size 20pt !important

  p, h3, h4, h5
    orphans 3
    widows 3

  h3, h4, h5
    page-break-after avoid

  #header-menu, #userbar, #nav, #sidebar, #footer, #comments, .toolbar, .nav-filter-wrapper,
  .topic-footer, .stat-performance, .topic-actions, .reply-header, .nav
    display none !important

  #wrapper
    -webkit-box-shadow none
    box-shadow none
    border 0
    padding 0

  #content
    width 100%
    margin 0

  .topic
    margin-bottom 100px
