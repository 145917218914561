@media (min-width:1200px)
  #comments
    body:not(.widemode) & .comment-level-40 .comment-wrapper
      margin-left 0
    body.widemode & .comment-level-50 .comment-wrapper
      margin-left 0

@media (min-width:1025px) and (max-width:1199px)
  #comments
    body:not(.widemode) & .comment-level-20 .comment-wrapper
      margin-left 0
    body.widemode & .comment-level-35 .comment-wrapper
      margin-left 0

@media (min-width:1025px) and (max-width:1100px)
  #running-ponies
    img:nth-of-type(5)
      display none

@media (min-width:1025px)
  #content-wrapper
    width 70%
    margin-right 5%
  #sidebar
    width 25%

@media (min-width:980px/*769px*/) and (max-width:1024px)
  #content-wrapper
    width 70%
    margin-right 2%
  #sidebar
    width 28%
  #running-ponies
    img:nth-of-type(5)
      display none
  #reply
    margin-left 20px
  #comments
    body:not(.widemode) & .comment-level-15 .comment-wrapper
      margin-left 0
    body.widemode & .comment-level-25 .comment-wrapper
      margin-left 0

@media (min-width:980px/*769px*/)
  #wrapper
    padding 16px 24px
  body.widemode
    #content-wrapper
      width 100%
      margin-right 0
    #sidebar
      display none
    #widemode
      .switch-label.normalmode.big-screen
        display inline-block
      .switch-label.widemode.big-screen
        display none
  #widemode
    .switch-label.widemode.big-screen
      display inline-block

@media (max-width:979px/*768px*/)
  #reply
    margin-left 0
  #wrapper
    margin 0
  #comments
    margin-bottom 16px
  #wrapper
    .table
      tr
        td,.cell-tab-inner
          padding 3px 0
    &.no-sidebar
      .faq-page
        width 90%
  #widemode
    .switch-label.widemode.small-screen
      display inline-block
  body.widemode
    #widemode
      .switch-label.normalmode.small-screen
        display inline-block
      .switch-label.widemode.small-screen
        display none

@media (max-width:767px)
  .topic
    margin-bottom:40px;
    &.link-preview-box
      margin-bottom:24px;
      padding:24px;
      .topic-list &
        margin:0 -24px 24px -24px;
  #comments
    .comments-header
      flex-wrap:wrap;
      gap:8px 24px;
      h3:first-child:nth-last-child(3) // применяется только когда в шапке обсуждения три элемента (заголовок, "оставить комментарий" и "подписаться")
        flex:1 1 100%;

@media (min-width:568px) and (max-width:1199px)
  #header-main
    position:relative;
    min-height:40px;
    padding-bottom:80px;
    .main-menu
      background:#1d2a4f;
      margin-right:32px;
      a
        font-size:18px;
        line-height:32px;
      a#logolink
        position:absolute;
        top:calc(100% - 80px);
        right:0;
        bottom:0;
        left:0;
        width:max-content;
        height:max-content;
        margin:auto;
        padding:0;
  #wrapper
    /*.profile .profile-stats
      display:grid;
      gap:16px 32px;
      .vote-profile
        grid-column:1 / span 2;
        justify-self:center;*/

@media (min-width:568px) and (max-width:979px/*768px*/)
  #header-main
    .main-menu
      a
        font-size:16px;
  #header-menu
    overflow visible
    .auth,#dropdown-user
      background #F4F4F6
      right:-380px;
      bottom:0;
      z-index:5;
      transition:right 0.3s ease;
    #dropdown-user
      width:284px;
      margin-top:-39px; padding-top:4px;
      padding-right:96px;
      .avatar
        right:16px;
    .auth
      width:350px;
      height:77px;
      padding:0 15px;
      margin-top:-15px;
      align-items:center;
      gap:16px;
      li
        margin:0;
  #wrapper
    /*width 140%*/
    position:relative;
    padding:0; /*10px 24px 20px 24px*/
    &.no-sidebar
      flex-flow row
      padding:10px 24px 20px 24px;
      #content-wrapper
        padding:0;
        margin-right:0;
  #content-wrapper
    width:100%;
    padding:10px 24px 20px 24px;
    margin-right:-380px;
    box-sizing:border-box;
  #sidebar
    /*width 30%
    position relative
    top 0
    left 100%*/
    position:relative;
    top:0; bottom:0;
    right:-380px;
    width:380px;
    z-index 5
    background-color #fff
    transition:right 0.3s ease;
    .block,.donate
      border-radius 0
      /*background #f4f4f6
      padding-bottom 40px
      margin 0*/
    .block.block-type-search
      padding:8px 16px /*40px 16px*/;
    #running-ponies
      /*background #f4f4f6*/
      margin 0
      padding:8px 16px;
      width:348px;
  #comments
    .comment-level-15 .comment-wrapper
      margin-left 0
  .comment-info
    li
      margin-right 10px
  #modal_write,#window_upload_img
    top 10px
  body.widemode
    /*#header-menu
      .auth
        display flex
      #dropdown-user
        display inline-block
    #sidebar
      left -30%*/
    #header-menu .auth, #header-menu #dropdown-user, #sidebar
      right:0;
      box-shadow: 0 4px 4px 2px rgba(100,100,100,0.502);
      &::before {
        content:'';
        position:absolute;
        top:0; right:100%; bottom:0; left:-1000px;
        background-color:rgba(100,100,100,0.4);
      }
    #toolbar
      display none!important

@media (max-width:567px)
  #container
    min-width:360px;
  #header-main
    min-height:60px;
    padding:0 16px 0 16px;
    .main-menu
      margin:0;
      flex:1 1 100%;
      a
        display none
        font-size 16px
        line-height 18px
        padding:0 2px
        margin-right:16px;
        &#logolink
          padding 0
          display block
          width 100%
          margin:0;
          text-align center
          line-height 36px
    .menu-social
      display:none;
      gap:8px;
      a
        flex:0 0 24px;
        width:24px;
        height:24px;
        background-size:48px;
        margin:0;
      a.tg
        background-position-y:-24px;
  #header-menu
    padding 0 16px
    margin 0
    min-height:77px;
    ul
      width 100%
    .auth,#dropdown-user,.modal.modal-login
      display none
  #wrapper
    padding 10px 16px 20px 16px
  #content-wrapper
    width 100%
    display block
  #sidebar
    width 100%
    display none
  #footer
    padding-bottom 40px
    background-size cover
  .topic
    margin-bottom:24px;
    &.link-preview-box
      margin-bottom:16px;
      padding:16px;
      .topic-list &
        margin:0 -16px 16px -16px;
  #comments
    .comment-level-5 .comment-wrapper
      margin-left 0
    .comment-info
      li
        margin-right 5px
  #widemode
    width 100%
    border-radius 0
    text-align right
    a
      margin-right 5px
  #toolbar
    bottom 0
    left 0
    height 35px
    right inherit
    top inherit
    z-index 101
    .toolbar-topic
      border 0 transparent solid
      margin 0
      display flex
      flex-flow row
      padding  6px 0 0 10px
      width auto
      height 25px
      a
        height 19px
        width 32px
        display inline-block
        i
          transform rotate(270deg)
          position relative
          top -8px
          left 6px
    .toolbar-update
      border 0 transparent solid
      padding 2px 0 0 5px
      margin 0
      width auto
      display flex
      flex-flow row
      align-items center
      #new_comments_counter
        padding:0 10px
        margin:0 0 0 16px;
  #wrapper
    .profile
      /*flex-wrap:wrap;*/
      display:grid;
      grid-template-columns:90px auto 32px;
      grid-template-rows:auto auto;
      gap:/*32px*/ 16px;
      .avatar
        /*flex:0 0 80px;*/
        width:80px;
        height:80px;
        grid-row:1 / span 2;
      /*.profile-name
        flex:1 1 calc(100% - 154px);*/
      .profile-stats
        /*flex:1 1 100%;
        flex-wrap:wrap;
        gap:min(7%, 32px);
        justify-content:center;*/
        grid-row:2;
        grid-column:auto span 2;
    .profile-info-about
      position static
      padding 0 5px
    .profile-dotted-list
      li
        background-image none
        display flex
        flex-flow column
        span
          font-weight bold
        strong
          width 100%
    .table.table-blogs
      tr
        .cell-join
          width 20px
        .cell-readers,.cell-rating
          width 40px
    .table.table-users
      tr
        .cell-name+th,.cell-name+td
          width 20px
        .cell-skill,.cell-rating
          width 40px
    .table.table-talk
      .cell-date.ta-r
        display none
      .cell-title
        width 70%
      .wrapper-content.wrapper-content-dark
        padding 10px 3px 20px 3px
    select
      max-width 100%!important
  #modal_write,#window_upload_img
    position fixed
    width 100%
    height auto
    margin 0
    left 0
    .modal-content
      padding 5px 5px 15px 5px!important
  #modal_write
    top 150px
    .write-list
      display flex
      flex-flow row
      flex-wrap wrap
      justify-content space-between
      align-items center
      li
        margin 0 5px!important
        padding 0!important
  #window_upload_img
    top 50px
    .modal-header
      text-align center
    .modal-content
      .nav.nav-pills.nav-pills-tabs
        justify-content center
  #window_login_form
    position static
    z-index 0
    .login.login-modal
      position fixed
      z-index 3000
      left 0
      top 50px
      width 100%
      .login-container
        width 96%
        padding 10px 5px
  body.widemode
    #sidebar
      display block
    #content-wrapper
      display none
    #header-main
      .main-menu
        margin-right:8px;
        a
          display inline-block
      #logolink
        display none
      .menu-social
        display:flex;
    #header-menu
      ul.auth
        display flex
      #dropdown-user
        display inline-block
        right 16px
        padding-right:72px;
        .avatar
          right:0;
    #header-menu
      .nav,#nav
        display none
    #toolbar .toolbar-update
      display:none;
  .profile-403
    background-size auto 128px
    padding-top 128px
